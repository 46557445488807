import React, { useState } from "react";

import Form_connect from "../Login/form_connect.js";

export default function White_rectangle() {
  return (
    <div id="white-rectangle" className="white-rectangle">
      <Form_connect />
    </div>
  );
}
