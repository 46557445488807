import "./FavoritesCard.scss";
import Timer from "../Timer/Timer.js";
import api from "../../api.js";
import React, {useEffect, useState} from "react";
import {atom, useAtom} from "jotai";
import {useNavigate} from "react-router-dom";



export default function FavoritesCard({ auctionId,title, endAuction,img,handleParentTimeOver}) {
    const navigate = useNavigate();
    const [isFavoris, setIsFavoris] = useState(true);


    const unfollow = async () => {
        const response = await api
            .put("/auction/unfollow/?auctionId=" + auctionId)
            .catch((e) => {
                console.error(e);
            });
        setIsFavoris(false)
    };

    const follow = async () => {
        const response = await api
            .put("/auction/follow/?auctionId=" + auctionId)
            .catch((e) => {
                console.error(e);
            });
        setIsFavoris(true)
    };

    const updateFollow = async () => {

        if (!isFavoris){
            follow();
        }
        else{
            unfollow();
        }
        setIsFavoris(!isFavoris);
    };

    const handleTimeOver = () => {
        handleParentTimeOver(auctionId);
    };

    return (
        <div className="card">
            <div className="card-body">
                <div className="card-title-wrapper">
                <h5 className="card-title">{title}</h5>
                </div>
                <div className="bottom-card">
                    <div className="left-bottom-card">
                <img src={img} className="card-img" alt="auction_img"></img>
                    </div>
                    <div className="right-bottom-card">

                    <Timer date={endAuction}  handleTimeOver={handleTimeOver}></Timer>

                        <div className="right-bottom-card-separator"></div>
                    <button className={ isFavoris ? " add-to-fav-btn" : " add-to-fav-btn fav" } onClick={() => updateFollow()}>
                        {isFavoris?

                            <svg  className="add-to-fav" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.23323 2.78583C9.42322 3.00794 9.70087 3.13581 9.99315 3.13581C10.2854 3.13581 10.5631 3.00794 10.7531 2.78583C12.4506 0.801272 15.1255 0.361127 17.1924 2.07906C19.315 3.84328 19.5772 6.83042 17.9325 8.94678C17.2417 9.83575 15.8675 11.2254 14.3741 12.6495C12.8974 14.0578 11.3571 15.4502 10.3725 16.3288C10.179 16.5014 10.0769 16.5915 9.99872 16.6508C9.9968 16.6523 9.99495 16.6537 9.99315 16.655C9.99136 16.6537 9.9895 16.6523 9.98758 16.6508C9.90936 16.5915 9.8073 16.5014 9.61382 16.3288C8.62922 15.4502 7.08892 14.0578 5.61217 12.6495C4.1188 11.2254 2.74464 9.83575 2.0538 8.94678C0.417958 6.84179 0.700981 3.86741 2.80429 2.07029C4.81788 0.349834 7.52816 0.792462 9.23323 2.78583Z" fill="#282828" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <circle cx="15" cy="13" r="4" fill="#E0E0E0"/>
                                <path d="M13 13H17" stroke="#212121" strokeLinecap="round"/>
                            </svg>
                            :
                            <svg className="add-to-fav"  width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.23323 2.78583C9.42322 3.00794 9.70087 3.13581 9.99315 3.13581C10.2854 3.13581 10.5631 3.00794 10.7531 2.78583C12.4506 0.801272 15.1255 0.361127 17.1924 2.07906C19.315 3.84328 19.5772 6.83042 17.9325 8.94678C17.2417 9.83575 15.8675 11.2254 14.3741 12.6495C12.8974 14.0578 11.3571 15.4502 10.3725 16.3288C10.179 16.5014 10.0769 16.5915 9.99872 16.6508C9.9968 16.6523 9.99495 16.6537 9.99315 16.655C9.99136 16.6537 9.9895 16.6523 9.98758 16.6508C9.90936 16.5915 9.8073 16.5014 9.61382 16.3288C8.62922 15.4502 7.08892 14.0578 5.61217 12.6495C4.1188 11.2254 2.74464 9.83575 2.0538 8.94678C0.417958 6.84179 0.700981 3.86741 2.80429 2.07029C4.81788 0.349834 7.52816 0.792462 9.23323 2.78583Z" fill="#282828" stroke="#282828" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <circle cx="15" cy="13" r="4" fill="#E0E0E0"/>
                                <path d="M13 13H17" stroke="#212121" strokeLinecap="round"/>
                                <path d="M15 15V11" stroke="#212121" strokeLinecap="round"/>
                            </svg>

                        }
                        {isFavoris?  "Ne plus suivre" : "Suivre ce véhicule" }
                    </button>
                        <button className="see-more"  onClick={() => navigate("/home/details/"+auctionId)}>Voir plus</button>

                    </div>
                </div>
            </div>
        </div>


            )
}
