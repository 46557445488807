import React from 'react'
import CopilotLandbot from '../CopilotLandbot/CopilotLandbot.js'
import SideBar from '../SideBar/SideBar.js'
import './Copilot.scss'
import Header from '../Header/Header.js'

export default function Copilot() {
  return (
    <div className='Copilot'>
       <Header onWhichPage={"copilot"} />
       <SideBar onWhichPage={"copilot"} />
       <div className='CopilotLandbot'>
         <CopilotLandbot />
       </div>
    </div>
  )
}
