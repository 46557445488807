import logo_dekstop from "../../assets/MECAVIN-LOGO-NOM-FD_BM_transparent.png";

export default function Blue_rectangle() {
  return (
    <div id="blue-rectangle" className="blue-rectangle">
      <div className="blue-rect-wrapper">
        <h1>Votre espace pour trouver les véhicules de vos clients.</h1>
        <div className="blue-rect-bottom">
          <img src={logo_dekstop} alt="logo Mecavin" />
          <p>
            Tous les véhicules sont identifiés avec notre technologie
            <span> Mecavin</span>
          </p>
        </div>
      </div>
    </div>
  );
}
