import api from "../../api.js";
import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import "./Favoris.scss";
import Header from "../Header/Header.js";
import {
  FindVehicule,
  Footer,
  HomeCarCard,
  Informations,
  SideBar,
} from "../index.js";
import noResult from "../../assets/noResult.svg";
import FavoritesCard from "../FavoritesCard/FavoritesCard.js";

const listFollowedAtom = atom([]);
const listCompaniesAtom = atom([]);
const idNinoxAtom = atom("");

export default function Favoris({ followStatus }) {
  const [listFollowed, setListFollowed] = useAtom(listFollowedAtom);
  const [listCompanies, setListCompanies] = useAtom(listCompaniesAtom);
  const [idNinox, setIdNinox] = useAtom(idNinoxAtom);

  useEffect(() => {
    allFollowed();
    getCompanies();
  }, []);

  const allFollowed = async () => {
    const response = await api
      .get("/auction/allFollowed/")
      .then((response) => {
        //filtre les doublons de auction_id
        const FilteredResponse = response.data.reduce((acc, current) => {
          const checkIfExist = acc.find(
            (data) => data.auction_id === current.auction_id
          );
          if (!checkIfExist) {
            return acc.concat([current]);
          }
          return acc;
        }, []);
        setListFollowed(FilteredResponse);
        return response.data;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  async function getCompanies() {
    const responseCompany = await api.get("/user/company").catch((err) => {
      console.log("Error: ", err);
    });
    let resultArray = [];
    if (responseCompany.data) {
      responseCompany.data.forEach((element) => {
        resultArray.push({
          value: element.siren,
          label: element.name,
          name: element.name,
          phone: element.phone,
          mail: element.mail,
          idNinox: idNinox,
        });
      });
      setListCompanies(resultArray);
    }
  }

  const DisplaySideBar = () => {
    if (listCompanies[0] != undefined && listCompanies.length > 0) {
      return <SideBar onWhichPage={"favs"} connectedUserData={listCompanies} />;
    }
  };


  async function handleParentTimeOver(auctionId) {

    const passedAuction = {
      auctionId: auctionId,
      onSale: "false",
    };

    await api
        .put(
            process.env.REACT_APP_BASE_URI + "/auction/only/auction",
            passedAuction
        )
        .catch((error) => console.log("error"));
  }
  function DisplayFollowed() {
    if (listFollowed === null || listFollowed.length === 0) {
      return (
        <div className="home-car-card-wrapper">
          <div className="no-result">
            <img
              className="img-no-result"
              src={noResult}
              alt="pas de résultats pour votre recherche"
            />
            <div className="text-no-result">
              <h2>Vous n'avez pas ajouté de véhicule en favori.</h2>
              <p>
                Rendez-vous sur la page Accueil pour consulter nos véhicules
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return listFollowed.map((data) => {
        const auctionId = data.auction_id;
        const title = data.auction_title.title;
        const endAuction = data.end_auction.end_auction;
        const img = data.auction_picture.Url;
        return (
          <FavoritesCard
            followStatus={followStatus}
            auctionId={auctionId}
            title={title}
            endAuction={endAuction}
            img={img}
            handleParentTimeOver={handleParentTimeOver}
          ></FavoritesCard>
        );
      });
    }
  }

  return (
    <div className="global-wrapper">
      <Header onWhichPage={"fav"} />
      <DisplaySideBar />
      <div className="global">
        <div className="favorites-filter-and-title">
          <div className="favorites-filter-wrapper">
            <div className="favorites-title"> Filtrer</div>
            <div className="favorites-filter">
              <FindVehicule />
            </div>
          </div>
          <div className="followed-title">Véhicules que vous suivez</div>
        </div>

        <div
          className={
            listFollowed.length === 0
              ? "favorites-auction-wrapper-missing"
              : "favorites-auction-wrapper"
          }
        >
          <DisplayFollowed />
        </div>
      </div>
      <Footer />
    </div>
  );
}
