import React from "react";
import "./How.scss";
import { atom, useAtom } from "jotai";
import CarouselHow, { CarouselHowItem } from "./CarouselHow.js";

import fstExpIcon from "../../assets/explanationIcon1.svg";
import sndExpIcon from "../../assets/explanationIcon2.svg";
import trdExpIcon from "../../assets/explanationIcon3.svg";
import fthExpIcon from "../../assets/explanationIcon4.svg";

/**
 * @todo Rendre le comment ça marche moins haut sur le format desktop
 *
 */

export const showDetailAtom = atom(false);

export default function How() {
  const [showDetail, setShowDetail] = useAtom(showDetailAtom);

  return (
    <div id="how" className={showDetail ? "How How-detailed" : "How"}>
      <div className="standard-how" onClick={() => setShowDetail(!showDetail)}>
        <h2>Comment ça fonctionne ?</h2>
        <button
          aria-label="learn-more"
          className={
            showDetail ? "learn-more learn-more-clicked" : "learn-more"
          }
          onClick={() => {
            setShowDetail(!showDetail);
          }}
        >
          <div className="toggle-btn-part"></div>
          <div className="toggle-btn-part"></div>
        </button>
      </div>
      <div className={showDetail ? "detail-how detail-show" : "detail-how"}>
        <CarouselHow>
          <CarouselHowItem
            icon={fstExpIcon}
            description={
              <p>
                Chaque acheteur soumet une offre,{" "}
                <span className="bold">inconnue</span> des autres.
              </p>
            }
          ></CarouselHowItem>
          <CarouselHowItem
            icon={sndExpIcon}
            description={
              <p>
                L’offre doit correspondre à{" "}
                <span className="bold">votre estimation du véhicule</span>.
              </p>
            }
          ></CarouselHowItem>
          <CarouselHowItem
            icon={trdExpIcon}
            description={
              <p>
                Vous serez prévenu <span className="bold">48H</span> avant,{" "}
                <span className="bold">24h</span> avant et lors des{" "}
                <span className="bold">dernières heures de l’offre</span>.
              </p>
            }
          ></CarouselHowItem>
          <CarouselHowItem
            icon={fthExpIcon}
            description={
              <p>
                Votre offre est la plus élevée et vous recevrez un{" "}
                <span className="bold">appel</span> d’un de nos{" "}
                <span className="bold">responsable des ventes</span>
              </p>
            }
          ></CarouselHowItem>
        </CarouselHow>
      </div>
    </div>
  );
}
