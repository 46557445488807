import React from "react";
import "./SectionTitle.scss";

export default function SectionTitle({ title }) {
  return (
    <div className="SectionTitle">
      <h2>{title}</h2>
      <span />
    </div>
  );
}
