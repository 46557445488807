import React from "react";
import "./SideBar.scss";
import { atom, useAtom } from "jotai";
import { createTheme } from "@mui/material/styles/index.js";
import { ThemeProvider } from "@mui/material/styles/index.js";
import { Link, useNavigate } from "react-router-dom";

import logoMecalife from "../../assets/sideBarMecalife.svg";
import goToHome from "../../assets/goToHome.svg";
import goToFavs from "../../assets/goToFavs.svg";
import goToCopilot from "../../assets/goToCopilot.svg";
import goToOffers from "../../assets/goToOffers.svg";
import logoutIcon from "../../assets/logoutIcon.svg";
import connectedIcon from "../../assets/connectedIcon.svg";
import { companyDataAtom } from "../../App.js";

import { SideBarFilter } from "../index.js";

export default function SideBar({ onWhichPage }) {
  const [companyData, setCompanyData] = useAtom(companyDataAtom);
  const navigate = useNavigate();

  const themeForSelect = createTheme({
    palette: {
      mode: "dark",
    },
  });

  function logout() {
    localStorage.removeItem("token");
    navigate("/login", { replace: true });
  }

  const addPointsToPhoneNumber = (phoneNumber) => {
    let newString = "";
    for (let i = 0; i < phoneNumber.length; i += 2) {
      newString += phoneNumber.slice(i, i + 2) + ".";
    }
    return newString.slice(0, -1); //Supprime le dernier point
  };

  return (
    <div className="SideBar">
      <div className="sidebar-top">
        <Link
          className="logoMecalife"
          to="/home"
          style={{ textDecoration: "none" }}
        >
          <img src={logoMecalife} alt="Mecalife (retour à l'accueil)" />
        </Link>
      </div>

      <div className="sidebar-content">
        <div className="main-navigation">
          <Link
            className={
              onWhichPage === "home"
                ? "main-nav-link current-nav-location"
                : "main-nav-link"
            }
            to="/home"
            style={{ textDecoration: "none" }}
          >
            <img src={goToHome} alt="" />
            <p>Accueil</p>
          </Link>
          <button
            className={
              onWhichPage === "favs"
                ? "main-nav-link current-nav-location"
                : "main-nav-link"
            }
            onClick={() => navigate("/home/favorites")}
          >
            <img src={goToFavs} alt="" />
            <p>Mes favoris</p>
          </button>
          <button
            className={
              onWhichPage === "copilot"
                ? "main-nav-link current-nav-location"
                : "main-nav-link"
            }
            onClick={() => navigate("/copilot")}
          >
            <img src={goToCopilot} alt="" />
            <p>Copilot</p>
          </button>
          {/* <button
            className={
              onWhichPage === "account"
                ? "main-nav-link current-nav-location go-to-account"
                : "main-nav-link go-to-account"
            }
              onClick={() => navigate("/home")}

          >
            <img src={goToAccount} alt="" />
            <p>Mon compte</p>
        </button>

          <button
            className={
              onWhichPage === "offers"
                ? "main-nav-link current-nav-location go-to-offers"
                : "main-nav-link go-to-offers"
            }
          >
            <img src={goToOffers} alt="" />
            <p>Mes offres</p>
          </button> */}
        </div>

        {onWhichPage === "home" && (
          <ThemeProvider theme={themeForSelect}>
            <SideBarFilter />
          </ThemeProvider>
        )}
      </div>
      <div className="sidebar-bottom">
        {companyData[0] && (
          <section className="connected-section">
            <img className="profile" src={connectedIcon} alt="" />
            <div className="connected-user-data">
              <p className="user-company-name">{companyData[0].name}</p>
              <p className="user-company-phone">
                {addPointsToPhoneNumber(companyData[0].phone)}
              </p>
            </div>
            <img
              className="logout"
              onClick={logout}
              src={logoutIcon}
              alt="Se déconnecter"
            />
          </section>
        )}
      </div>
    </div>
  );
}
