import React, { useRef, useEffect } from "react";
import { useAtom } from "jotai";
import "./HomeFilterTags.scss";

import filterIcon from "../../assets/filter-icon.svg";

import { is1200pxAtom } from "../../App.js";
import { isFilterOpenAtom } from "../Home/Home.js";

export default function HomeFilterTags() {
  const stickyElmRef = useRef(null);

  /**
   * Ce useEffect permet d'ajouter la classe is-sticky lorsque le composant
   * arrive en haut de l'écran et devient sticky.
   */
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        stickyElmRef.current.classList.toggle(
          "isSticky",
          entry.intersectionRatio < 1
        );
      },
      { threshold: [1] }
    );

    if (stickyElmRef.current) {
      observer.observe(stickyElmRef.current);
    }

    return () => {
      if (stickyElmRef.current) {
        observer.unobserve(stickyElmRef.current);
      }
    };
  }, []);

  // State Jotai
  const [is1200px] = useAtom(is1200pxAtom);
  const [isFilterOpen, setIsFilterOpen] = useAtom(isFilterOpenAtom);

  return (
    <div ref={stickyElmRef} className="HomeFilterTags">
      {!is1200px && (
        <button
          onClick={() => {
            setIsFilterOpen(true);
          }}
          className="all-filters-btn"
        >
          <p>Tous les filtres</p>
          <img src={filterIcon} alt="Ouvrir les filtres" />
        </button>
      )}
    </div>
  );
}
