import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import { createPath, useLocation, useParams } from "react-router";
import axios from "axios";
import "./DetailsV2.scss";
import Pricing from "../Pricing/Pricing.js";
import DeposerOffre from "../DeposerOffre/DeposerOffre.js";
import api from "../../api.js";

import {
  SideBar,
  UsefulLinks,
  ExpertComment,
  VehiculeDetails,
  PictureCardV2,
  Informations,
  MakeAnOfferModal,
  OffersDetails,
  Header,
  SectionTitle,
  Footer,
  AuctionTitle,
  FavorisElement,
} from "../index.js";

import {
  is760pxAtom,
  is970pxAtom,
  is1200pxAtom,
  companyDataAtom,
} from "../../App.js";

import HeaderDetails from "../HeaderDetails/HeaderDetails.js";
import { Navigator } from "../Navigator/Navigator.js";

// atoms Jotai

import { reloadAtom } from "./MakeAnOfferModal/MakeAnOfferModal.js";

// Auction atom
import { filteredAuctionListAtom } from "../../App.js";
export const picturesAuctionObjectAtom2 = atom({});
export const auctionAtom = atom({});
export const linksListAtom = atom([]);
export const listOffersAtom = atom([]);
export const askPriceAtom = atom("");
export const idNinoxAtom = atom("");
export const userCompaniesListAtom = atom([]);
export const followAtom = atom(false);
// Status atom
export const isModalOpenAtom = atom(false);

//Pricing data atom
export const pricingDataAtom = atom([]);

// User info atom
export const listCompaniesAtom = atom([]);

export default function DetailsV2() {
  let { state } = useLocation();
  const params = useParams();
  const auctionId = params.auctionId;

  // state Jotai
  // AUCTION LINK ATOM
  const [follow, setFollow] = useAtom(followAtom);
  const [companyData, setCompanyData] = useAtom(companyDataAtom);
  const [auction, setAuction] = useAtom(auctionAtom);
  const [linksList, setLinksList] = useAtom(linksListAtom);
  const [picturesAuctionObject2, setPicturesAuctionObject2] = useAtom(
    picturesAuctionObjectAtom2
  );
  const [listOffers, setlistOffers] = useAtom(listOffersAtom);
  const [varAskPrice, setVarAskPrice] = useAtom(askPriceAtom);
  const [idNinox, setIdNinox] = useAtom(idNinoxAtom);
  const [filteredAuctionList, setFilteredAuctionList] = useAtom(
    filteredAuctionListAtom
  );

  // User atom
  const [listCompanies, setListCompanies] = useAtom(listCompaniesAtom);
  const [userCompaniesList, setUserCompanyList] = useAtom(
    userCompaniesListAtom
  );

  // Format atom
  const [is760px, setIs760px] = useAtom(is760pxAtom);
  const [is970px, setIs970px] = useAtom(is970pxAtom);
  const [is1200px, setIs1200px] = useAtom(is1200pxAtom);

  // Status atom
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);
  const [reload, setReload] = useAtom(reloadAtom);

  // Pricing data atom
  const [pricingData, setPricingData] = useAtom(pricingDataAtom);

  const [isIdNinoxUpdated, setIsIdNinoxUpdated] = useState(false);

  useEffect(() => {
    setlistOffers([]);
    getAuction();
    getOffers();
  }, [auctionId]);

  useEffect(() => {
    getPictures();
    getCompanies();
    getLinks();
  }, [auction]);

  useEffect(() => {
    if (companyData != undefined && companyData.length != 0) {
      setCompanyInfo(companyData);
    }
  }, [companyData]);

  async function getAuction() {
    if (state) {
      setAuction(state.carData);
      setVarAskPrice(state.carData.auction.startPrice);
      setIdNinox(state.carData.auction.idNinox);
      setIsIdNinoxUpdated(true);
    } else {
      const response = await api.get("/auction/" + auctionId).catch((e) => {
        console.error(e);
      });
      setIdNinox(response.data.auction.idNinox);
      setVarAskPrice(response.data.auction.startPrice);
      setAuction(response.data);
      setIsIdNinoxUpdated(true);
    }
    const isFollowing = await api
      .get("/auction/isFollowing/" + auctionId)
      .catch((e) => {
        console.error(e);
      });
    setFollow(isFollowing.data);
  }

  async function getDataByIdNinox() {
    const resp = await axios
      .post(process.env.REACT_APP_DATA_PRICING, { id_ninox: idNinox })
      .catch((error) => {
        console.error(error);
      });
    setPricingData(resp.data);
  }

  useEffect(() => {
    if (isIdNinoxUpdated) {
      getDataByIdNinox();
    }
  }, [idNinox, isIdNinoxUpdated]);

  async function getOffers() {
    let OffersDetails;
    const responseOffers = await api
      .get("/auction/offers/" + auctionId)
      .catch((err) => {
        console.log("Error: ", err);
      });

    OffersDetails = responseOffers.data;
    //trier par date les offres.
    OffersDetails.sort((a, b) => (a.offers.price < b.offers.price ? 1 : -1));
    setlistOffers(OffersDetails);
    setReload(true);
  }

  async function getCompanies() {
    const responseCompany = await api.get("/user/company").catch((err) => {
      console.log("Error: ", err);
    });
    let resultArray = [];
    let userCompaniesName = [];
    if (responseCompany.data) {
      responseCompany.data.forEach((element) => {
        userCompaniesName.push(element.name);
        resultArray.push({
          value: element.siren,
          label: element.name,
          name: element.name,
          phone: element.phone,
          mail: element.mail,
          idNinox: idNinox,
        });
      });
      setUserCompanyList(userCompaniesName);
      setListCompanies(resultArray);
    }
  }

  function setCompanyInfo() {
    let resultArray = [];
    let userCompaniesName = [];
    if (companyData) {
      companyData.forEach((element) => {
        userCompaniesName.push(element.name);
        resultArray.push({
          value: element.siren,
          label: element.name,
          name: element.name,
          phone: element.phone,
          mail: element.mail,
          idNinox: idNinox,
        });
      });
      setUserCompanyList(userCompaniesName);
      setListCompanies(resultArray);
    }
  }

  async function getLinks() {
    if (auctionId) {
      const response = await api
        .get("/auction/links/" + auctionId)
        .catch((e) => {
          console.error(e);
        });
      if (response != undefined) {
        setLinksList(response.data);
      }
    }
  }

  async function getPictures() {
    if (auctionId) {
      const response = await api
        .get("/auction/cloudinary/" + auctionId)
        .catch((e) => {
          console.error(e);
        });
      const tmpArray = picturesAuctionObject2;
      const variableTmp = {
        [auctionId]: response.data,
        ...tmpArray,
      };
      setPicturesAuctionObject2(variableTmp);
    }
  }

  //Display for components
  const DisplayLinks = () => {
    if (auction.auctionInfo) {
      if (linksList.length != 0) {
        return <UsefulLinks urlList={linksList} />;
      }
    }
  };

  const DisplayPictures = () => {
    if (auction.auctionInfo) {
      return (
        <PictureCardV2
          photosArray={picturesAuctionObject2[auctionId]}
        ></PictureCardV2>
      );
    }
  };
  const DisplayHeaderDetails = () => {
    if (
      auction.auctionInfo &&
      typeof auction.auction.endAuction !== "undefined"
    ) {
      return (
        <HeaderDetails
          title={auction.auctionInfo.title}
          endAuction={auction.auction.endAuction}
          follow={follow}
          auctionId={auctionId}
          filteredAuctionList={filteredAuctionList}
        />
      );
    }
  };

  const DisplayExpertComment = () => {
    if (auction.auctionInfo) {
      return <ExpertComment comment={auction.auctionInfo.description} />;
    }
  };

  const DisplayPricing = () => {
    if (auction.auctionInfo) {
      return <Pricing />;
    }
  };

  const DisplayInformations = () => {
    if (auction.auctionInfo) {
      return (
        <>
          <Informations
            nbStars={auction.auctionInfo.noteMecavin}
            optionsPrice={auction.auctionInfo.optionsPrice}
            frevo={auction.auctionInfo.fees}
          />
        </>
      );
    }
  };

  const DisplayVehiculeDetails = () => {
    if (auction.auctionInfo) {
      return <VehiculeDetails carData={auction.auctionInfo} />;
    }
  };

  const DisplayModal = () => {
    return (
      <>
        {isModalOpen && auction.auctionInfo && (
          <>
            <div className="modal-background"></div>
            <MakeAnOfferModal
              title={auction.auctionInfo.title}
              auctionId={auctionId}
              endAuction={auction.auction.endAuction}
            />
          </>
        )}
      </>
    );
  };

  const DisplayTitle = () => {
    if (auction.auctionInfo) {
      return <AuctionTitle title={auction.auctionInfo.title} />;
    }
  };

  const DisplaySideBar = () => {
    if (listCompanies[0] != undefined) {
      return <SideBar />;
    }
  };

  //Display for breakpoints
  const Display760px = () => {
    return (
      <main>
        <section className="details-section">
          <div className="details-section-group">
            <DisplayPictures />
            <DisplayTitle />
            <DisplayLinks />
            <DisplayInformations />
          </div>
          <DisplayVehiculeDetails />
          <DisplayPricing />
        </section>
        <section className="details-section">
          <div>
            <SectionTitle title="Déposer une offre" />
            <DeposerOffre
              auctionInfoProps={auction}
              linksInfoProps={linksList}
            />
            {/*<OffersDetails stockAuctionId={auctionId} />*/}
          </div>

          <DisplayExpertComment />
        </section>
      </main>
    );
  };

  const Display970px = () => {
    return (
      <main>
        <section className="details-section">
          <DisplayPictures />
          <DisplayVehiculeDetails />
          <DisplayExpertComment />

          <DisplayPricing />
        </section>
        <section className="details-section">
          <div>
            <SectionTitle title="Déposer une offre" />
            <DeposerOffre
              auctionInfoProps={auction}
              linksInfoProps={linksList}
            />
            {/*<OffersDetails stockAuctionId={auctionId} />*/}
          </div>
          <div>
            <SectionTitle title="Informations" />
            <DisplayInformations />
          </div>

          <DisplayLinks />
        </section>
      </main>
    );
  };

  const Display1200px = () => {
    return (
      <div className="details-desktop-content">
        <main>
          <section className="details-section">
            <DisplayPictures />
            <DisplayVehiculeDetails />
            <DisplayExpertComment />
            <DisplayPricing />
          </section>
          <section className="details-section">
            <div>
              <SectionTitle title="Déposer une offre" />
              <DeposerOffre
                auctionInfoProps={auction}
                linksInfoProps={linksList}
              />
              {/*<OffersDetails stockAuctionId={auctionId} />*/}
            </div>
            <div>
              <SectionTitle title="Informations" />
              <DisplayInformations />
            </div>
            <DisplayLinks />
          </section>
        </main>
      </div>
    );
  };

  const MobileDisplay = () => {
    return (
      <div className="details-display-mobile">
        <Header />
        <div className="details-white-background">
          <div className="picture-fav-group">
            {auction.auctionInfo && (
              <FavorisElement
                auctionId={auction.auctionInfo.auctionId}
                followStatus={follow}
              ></FavorisElement>
            )}
            <DisplayPictures />
          </div>
          <DisplayTitle />
        </div>
        <div className="details-grey-background">
          <DeposerOffre auctionInfoProps={auction} linksInfoProps={linksList} />
          {/*<OffersDetails stockAuctionId={auctionId} />*/}
          <DisplayVehiculeDetails />
          <DisplayExpertComment />

          <DisplayPricing />
        </div>
        <Footer />
      </div>
    );
  };

  const TabletDisplay = () => {
    return (
      <>
        <Header />
        {is970px ? <Display970px /> : <Display760px />}
        <Footer />
      </>
    );
  };

  const DesktopDisplay = () => {
    return (
      <div className="details-desktop-display">
        <DisplayHeaderDetails />
        <Display1200px />
        <Footer />
      </div>
    );
  };

  return (
    <div className="DetailsV2">
      <DisplayModal />

      {!is760px && <MobileDisplay />}
      {!is1200px && is760px && <TabletDisplay />}
      {!is1200px && <DisplayHeaderDetails />}

      {is1200px && <DisplaySideBar />}
      {is1200px && <DesktopDisplay />}
    </div>
  );
}
