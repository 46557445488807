import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";

import OutlinedInput from "@mui/material/OutlinedInput/index.js";
import InputLabel from "@mui/material/InputLabel/index.js";
import MenuItem from "@mui/material/MenuItem/index.js";
import FormControl from "@mui/material/FormControl/index.js";
import ListItemText from "@mui/material/ListItemText/index.js";
import Select from "@mui/material/Select/index.js";
import Checkbox from "@mui/material/Checkbox/index.js";
import SearchBar from "../SearchBar/SearchBar.js";

import "./SideBarFilter.scss";

//Atom Jotai
import {
  filteredAuctionListAtom,
  auctionListAtom,
  is1200pxAtom,
} from "../../../App.js";

import { isFilterOpenAtom } from "../../Home/Home.js";

//Some styling for the select MUI component
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      marginLeft: "-5px",
      marginTop: "5px",
    },
  },
};

//Complete lists
let allBrands = [];
let allModels = [];
let allCategories = [];
const allFuels = ["DIESEL", "ESSENCE", "HYBRIDE ESSENCE"];
const allGearboxes = ["AUTOMATIQUE", "MANUELLE"];

export default function SideBarFilter() {
  //State Jotai
  const [allAuctions, setAllAuctions] = useAtom(auctionListAtom);
  const [filteredAuctions, setFilteredAuctions] = useAtom(
    filteredAuctionListAtom
  );
  const [is1200px, setIs1200px] = useAtom(is1200pxAtom);
  const [isFilterOpen, setIsFilterOpen] = useAtom(isFilterOpenAtom);

  //Available brand, model and category
  const [modifiedBrands, setModifiedBrands] = useState([]);
  const [modifiedModels, setModifiedModels] = useState([]);
  const [modifiedCategories, setModifiedCategories] = useState([]);

  //Chosen brand, model and category
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [categories, setCategories] = useState([]);

  //Chose fuel type and gearbox
  const [fuelTypes, setFuelTypes] = useState(allFuels);
  const [gearboxes, setGearboxes] = useState(allGearboxes);

  //First UseEffect to run -> creating brand and model list
  useEffect(() => {
    createBrandAndModelList(allAuctions);
  }, [allAuctions]);

  //Creating brand and model lists function
  const createBrandAndModelList = (allSalesObject) => {
    const tmpBrands = [];
    const tmpModels = [];

    for (const auctionId in allSalesObject) {
      const auction = allSalesObject[auctionId];
      const brand = auction.auctionInfo.brand;
      const model = auction.auctionInfo.model;

      if (!tmpBrands.includes(brand)) {
        tmpBrands.push(brand);
      }

      if (!tmpModels.includes(model)) {
        tmpModels.push(model);
      }
    }

    allBrands = tmpBrands;
    allModels = tmpModels;
    setModifiedBrands(tmpBrands);
    setModifiedModels(tmpModels);
  };

  //Handle change functions
  const handleBrandChange = (event) => {
    const {
      target: { value },
    } = event;
    setBrands(typeof value === "string" ? value.split(",") : value);
  };

  const handleModelChange = (event) => {
    const {
      target: { value },
    } = event;
    setModels(typeof value === "string" ? value.split(",") : value);
  };

  const handleFuelChange = (event) => {
    const { name, checked } = event.target;

    if (fuelTypes.length !== 3 && checked) {
      setFuelTypes([...fuelTypes, name]);
    } else if (fuelTypes.length === 3 && checked) {
      setFuelTypes([name]);
    } else {
      //not checked
      const tmpArray = fuelTypes.filter((item) => item !== name);
      if (tmpArray.length === 0) {
        setFuelTypes(allFuels);
      } else {
        setFuelTypes(tmpArray);
      }
    }
  };

  const handleGearboxChange = (event) => {
    const { name, checked } = event.target;

    if (gearboxes.length !== 2 && checked) {
      setGearboxes([...gearboxes, name]);
    } else if (gearboxes.length === 2 && checked) {
      setGearboxes([name]);
    } else {
      //not checked
      const tmpArray = gearboxes.filter((item) => item !== name);
      if (tmpArray.length === 0) {
        setGearboxes(allGearboxes);
      } else {
        setGearboxes(tmpArray);
      }
    }
  };

  /**
   * This functions resets all checkboxes
   */
  const uncheckAllCheckboxes = () => {
    const checkboxes = document.querySelectorAll(
      '.checkbox-group input[type="checkbox"]'
    );
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
    setFuelTypes(allFuels);
    setGearboxes(allGearboxes);
  };

  //Choosing brands fires this useEffect
  useEffect(() => {
    modifyModelsAndCategories(allAuctions);
  }, [brands]);

  /**
   * Cette fonction modifie les options de modèle disponible
   * à partir de la liste de marque choisi
   */
  const modifyModelsAndCategories = (allSalesObject) => {
    if (brands.length != 0) {
      const tmpModels = [];
      for (const auctionId in allSalesObject) {
        const auction = allSalesObject[auctionId];
        const brand = auction.auctionInfo.brand;
        const model = auction.auctionInfo.model;

        if (brands.includes(brand) && !tmpModels.includes(model)) {
          tmpModels.push(model);
        }
      }
      setModifiedModels(tmpModels);
    } else {
      setModifiedModels(allModels);
    }
  };

  /**
   * This function filters the auction list based on the chosen filters
   * And it sets the filterAuctionList state variable
   * So it displays only the auctions that match the filters
   */
  function filterData(
    data,
    selectedBrands,
    selectedModels,
    selectedFuels,
    selectedGearboxes
  ) {
    const filteredCars = data.filter((car) => {
      const { brand, model, fuelType, gearbox } = car.auctionInfo;

      const brandMatch =
        selectedBrands.length === 0 || selectedBrands.includes(brand);

      const modelMatch =
        selectedModels.length === 0 || selectedModels.includes(model);

      const fuelMatch =
        selectedFuels.length === 0 || selectedFuels.includes(fuelType);

      const gearboxMatch =
        selectedGearboxes.length === 0 || selectedGearboxes.includes(gearbox);

      return brandMatch && modelMatch && fuelMatch && gearboxMatch;
    });

    setFilteredAuctions(filteredCars);

    if (isFilterOpen) {
      //closes the filter page on mobile
      setIsFilterOpen(false);
    }
  }

  return (
    <div className="SideBarFilter">
      <p className="filter-title">FILTRES</p>
      <SearchBar />
      <div className="filter-wrapper">
        <FormControl size="small">
          <InputLabel id="brand-select-label" sx={{ fontSize: "12px" }}>
            Marque
          </InputLabel>
          <Select
            labelId="brand-select-label"
            id="brand-select"
            multiple
            value={brands}
            onChange={handleBrandChange}
            input={<OutlinedInput label="Marque" sx={{ fontSize: "12px" }} />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {modifiedBrands.map((brand) => (
              <MenuItem key={brand} value={brand} sx={{ p: 0 }}>
                <Checkbox size="small" checked={brands.indexOf(brand) > -1} />
                <ListItemText primary={brand} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl size="small">
          <InputLabel id="model-select-label" sx={{ fontSize: "12px" }}>
            Modèle
          </InputLabel>
          <Select
            labelId="model-select-label"
            id="model-select"
            multiple
            value={models}
            onChange={handleModelChange}
            input={<OutlinedInput label="Modèle" sx={{ fontSize: "12px" }} />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {modifiedModels.map((model) => (
              <MenuItem key={model} value={model} sx={{ p: 0 }}>
                <Checkbox size="small" checked={models.indexOf(model) > -1} />
                <ListItemText primary={model} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <fieldset className="filter-checkbox">
          <legend>Energie</legend>
          <div className="checkbox-group">
            <input
              className="input-box"
              type="checkbox"
              name="DIESEL"
              id="DIESEL"
              onChange={handleFuelChange}
            />
            <label htmlFor="DIESEL">Diesel</label>
          </div>
          <div className="checkbox-group">
            <input
              className="input-box"
              type="checkbox"
              name="ESSENCE"
              id="ESSENCE"
              onChange={handleFuelChange}
            />
            <label htmlFor="ESSENCE">Essence</label>
          </div>
          <div className="checkbox-group">
            <input
              className="input-box"
              type="checkbox"
              name="HYBRIDE ESSENCE"
              id="HYBRIDE ESSENCE"
              onChange={handleFuelChange}
            />
            <label htmlFor="HYBRIDE ESSENCE">Hybride</label>
          </div>
        </fieldset>
        <fieldset className="filter-checkbox">
          <legend>Boite de vitesse</legend>
          <div className="checkbox-group">
            <input
              className="input-box"
              type="checkbox"
              name="AUTOMATIQUE"
              id="AUTOMATIQUE"
              onChange={handleGearboxChange}
            />
            <label htmlFor="AUTOMATIQUE">Automatique</label>
          </div>
          <div className="checkbox-group">
            <input
              className="input-box"
              type="checkbox"
              name="MANUELLE"
              id="MANUELLE"
              onChange={handleGearboxChange}
            />
            <label htmlFor="MANUELLE">Manuelle</label>
          </div>
        </fieldset>
      </div>
      <div className="filter-btns">
        <button
          className="reset-filter"
          onClick={() => {
            setBrands([]);
            setModels([]);
            setCategories([]);
            uncheckAllCheckboxes();
            setFilteredAuctions(allAuctions);
          }}
        >
          Réinitialiser
        </button>
        <button
          className="submit-filter"
          onClick={() => {
            filterData(allAuctions, brands, models, fuelTypes, gearboxes);
          }}
        >
          Rechercher
        </button>
      </div>
    </div>
  );
}
