import React, { useRef, useEffect } from "react";
import "./HomeMobileFilter.scss";

import { useAtom } from "jotai";

import { SideBarFilter } from "../index.js";
import closingCross from "../../assets/closingCross.svg";

import { isFilterOpenAtom } from "../Home/Home.js";

export default function HomeMobileFilter() {
  const [isFilterOpen, setIsFilterOpen] = useAtom(isFilterOpenAtom);
  const filterPage = useRef(null);

  useEffect(() => {
    if (isFilterOpen === true) {
      filterPage.current.style.left = "0%";
    } else {
      filterPage.current.style.left = "-100%";
    }
  }, [isFilterOpen]);

  return (
    <div ref={filterPage} className="HomeMobileFilter">
      <div className="mobile-filter-header">
        <h1>FILTRER</h1>
        <img
          onClick={() => {
            setIsFilterOpen(false);
          }}
          src={closingCross}
          alt="Ferme la page de filtre"
        />
      </div>

      <div className="mobile-filter-content">
        <SideBarFilter />
      </div>
    </div>
  );
}
