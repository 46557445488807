import React from "react";
import "./UsefulLinks.scss";

import { SectionTitle } from "../../index.js";

export default function UsefulLinks({ urlList }) {
  const LinksToDisplay = () => {
    //Liste triée de sorte à ce que le rapport mecavin soit à la fin de la liste
    urlList.sort((a, b) => {
      if (a.title === "RAPPORT MECAVIN") return 1;
      if (b.title === "RAPPORT MECAVIN") return -1;
      return 0;
    });

    const html = urlList.map((link, index) => (
      <div key={index} className="link">
        <div className="link-description">{link.title}</div>
        <a
          className={
            link.title === "RAPPORT MECAVIN"
              ? "link-content mecavin-link"
              : "link-content"
          }
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <p>Voir</p>
        </a>
      </div>
    ));

    return (
      <div className="UsefulLinks">
        {/* <SectionTitle title="Liens utiles" /> */}
        <div className="links-card">{html}</div>
      </div>
    );
  };

  return <LinksToDisplay />;
}
