import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";

import {
  Login,
  Home,
  PhotosComponent,
  PhotosZoomComponent,
  DetailsV2,
  Copilot
} from "./components/index.js";

import ScrollToTop from "./ScrollToTop.js";
import { atom, useAtom } from "jotai";
import api from "./api.js";
import Favoris from "./components/Favoris/Favoris.js";

export const companyDataAtom = atom([]);

export const isAuthenticatedAtom = atom(localStorage.getItem("token") !== null);

//INIT ET EXPORT DES ATOMS JOTAI

//Anciens Breakpoints
export const isTabletAtom = atom(
  window.matchMedia("(min-width : 768px)").matches
);
export const isDesktopAtom = atom(
  window.matchMedia("(min-width : 1200px)").matches
);

//Nouveau Breakpoints
export const is760pxAtom = atom(
  window.matchMedia("(min-width : 760px)").matches
);
export const is970pxAtom = atom(
  window.matchMedia("(min-width : 970px)").matches
);
export const is1200pxAtom = atom(
  window.matchMedia("(min-width : 1200px)").matches
);
export const is1600pxAtom = atom(
  window.matchMedia("(min-width : 1600px)").matches
);

export const isLoadedAtom = atom(false);
export const filteredAuctionListAtom = atom([]);
export const auctionListAtom = atom([]);

function App() {
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);
  const [filteredAuctionList, setFilteredAuctionList] = useAtom(
    filteredAuctionListAtom
  );
  const [ourLatestCars, setOurLatestCars] = useAtom(auctionListAtom);

  // Anciens Breakpoints
  const [isTablet, setIsTablet] = useAtom(isTabletAtom);
  const [isDesktop, setIsDesktop] = useAtom(isDesktopAtom);
  const [companyData, setCompanyData] = useAtom(companyDataAtom);

  // Nouveaux Breakpoints
  const [is760px, setIs760px] = useAtom(is760pxAtom);
  const [is970px, setIs970px] = useAtom(is970pxAtom);
  const [is1200px, setIs1200px] = useAtom(is1200pxAtom);
  const [is1600px, setIs1600px] = useAtom(is1600pxAtom);

  useEffect(() => {
    if (isAuthenticated) {
      getCompanies();
      getAllSales();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    // Anciens Breakpoints
    window
      .matchMedia("(min-width:768px)")
      .addEventListener("change", (e) => setIsTablet(e.matches));

    window
      .matchMedia("(min-width:1200px)")
      .addEventListener("change", (e) => setIsDesktop(e.matches));

    // Nouveaux Breakpoints
    window
      .matchMedia("(min-width:760px)")
      .addEventListener("change", (e) => setIs760px(e.matches));

    window
      .matchMedia("(min-width:970px)")
      .addEventListener("change", (e) => setIs970px(e.matches));
    window
      .matchMedia("(min-width:1200px)")
      .addEventListener("change", (e) => setIs1200px(e.matches));

    window
      .matchMedia("(min-width:1600px)")
      .addEventListener("change", (e) => setIs1600px(e.matches));
  }, []);

  function compare(auction1, auction2) {
    if (auction1.auction.endAuction < auction2.auction.endAuction) {
      return -1;
    } else if (auction1.auction.endAuction > auction2.auction.endAuction) {
      return 1;
    }
    return 0;
  }

  async function getAllSales() {
    const tmpFilteredAuctionList = [];
    setIsLoaded(false);
    const response = await api
      .get(process.env.REACT_APP_BASE_URI + "/auction/allSales")
      .catch((e) => {
        console.error(e);
      });

    const tmpData = Object.values(response.data);
    tmpData.sort(compare);
    tmpData.map((auction, index) => {
      auction.order = index;
    });

    setOurLatestCars(Object.values(response.data));
    setFilteredAuctionList(tmpData);
    setIsLoaded(true);

    /**
     * [NOTE]
     *
     * Initialement la liste d'auction filtré est égale à la liste totale d'auctions.
     */
  }

  // Vérifie si le token est expiré
  async function getCompanies() {
    const responseCompany = await api.get("/user/company").catch((err) => {
      console.log("Error: ", err);
    });
    setCompanyData(responseCompany.data);
  }

  // Vérifie si le token est expiré
  const handleTokenExpiration = () => {
    const expirationStorage = localStorage.getItem("expiration");
    if (expirationStorage) {
      const expirationDate = new Date(expirationStorage);
      const currentDate = new Date();

      if (currentDate > expirationDate) {
        localStorage.removeItem("token");
        localStorage.removeItem("expiration");
        setIsAuthenticated(false);
      }
    }
  };

  handleTokenExpiration();

  return (
    <HelmetProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path={"/login"} element={<Login />} />
          <Route path={"/login/:token"} element={<Login />} />
          <Route
            path={"/home"}
            element={
              isAuthenticated ? (
                <Home />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path={"/home/details/:auctionId"}
            element={
              isAuthenticated ? (
                <DetailsV2 />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path={"/home/details/:auctionId/photos"}
            element={
              isAuthenticated ? (
                <PhotosComponent />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path={"/home/favorites"}
            element={
              isAuthenticated ? (
                <Favoris />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path={"/home/details/:auctionId/photos/:photoId"}
            element={
              isAuthenticated ? (
                <PhotosZoomComponent />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
          <Route
            path={"*"}
            element={
              isAuthenticated ? (
                <Navigate to="/home" replace={true} />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
           <Route
            path={"/copilot"}
            element={
              isAuthenticated ? (
              <Copilot />
              ) : (
                <Navigate to="/login" replace={true} />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
