import React, { useState, useEffect  } from 'react';
import { atom, useAtom } from "jotai";
import './SearchBar.scss';
import searchLogo from "../../../assets/searchLogo.svg" ;

import {filteredAuctionListAtom, auctionListAtom} from "../../../App.js"

export default function SearchBar() {
    const [auctionList, setAuctionList] =  useAtom(auctionListAtom); 
    const [filteredResults, setFilteredResults] = useAtom(filteredAuctionListAtom);
    const [searchText, setSearchText] = useState('');

    function valueToString(value) {
        if (value === null || value === undefined) {
            return '';
        }
        return value.toString();
    }

    useEffect(() => {
        if (auctionList) {
          const filteredData = auctionList.filter(item =>
            Object.values(item.auctionInfo).some(value =>
              valueToString(value).toLowerCase().includes(searchText.toLowerCase())
            )
          );
          setFilteredResults(filteredData);
        }
      }, [searchText, auctionList]);

    const handleChange = (e) => {
        const searchText = e.target.value;
        setSearchText(searchText);
    };
    
    
    return (
        <div className="search-bar">
            <img src={searchLogo}/>           
            <input
                className="search-input"
                type="text"
                value={searchText}
                onChange={handleChange}
                placeholder="Marque, Modèle..."
            />
        </div>
    );
      
}
