import React from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { useLocation, useNavigate } from "react-router-dom";
import "./PhotosZoomComponent.scss";

import { Loader } from "../../../index.js";

import goBackToDetails from "../../../../assets/goBackArrow.svg";
import goBackToImageGallery from "../../../../assets/goBackCrossIcon.svg";

export default function PhotosZoomComponent() {
  let { state } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="PhotosZoomComponent">
      <button
        onClick={() => {
          navigate(-1);
        }}
        className="goBackToDetails"
      >
        <img src={goBackToDetails} alt="naviguer -1" />
      </button>
      <Loader />
      <picture>
        <source
          media="(max-width: 600px)"
          srcSet={state.photo[1] ? state.photo[1] : state.photo[0]}
        />
        <source
          media="(min-width: 601px)"
          srcSet={state.photo[2] ? state.photo[2] : state.photo[0]}
        />
        <img
          src={state.photo[2] ? state.photo[2] : state.photo[0]}
          alt="Image avec meilleure résolution"
        />
      </picture>
    </div>
  );
}
