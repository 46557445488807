import React, { useEffect } from "react";
import { useAtom } from "jotai";
import "./Login.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../api.js";

import White_rectangle from "../rectangles/White_rectangle.js";
import Blue_rectangle from "../rectangles/blue_rectangle.js";

import { isDesktopAtom } from "../../App.js";
import { isAuthenticatedAtom } from "../../App.js";

function Login() {
  const [isDesktop, setIsDesktop] = useAtom(isDesktopAtom);
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
  let navigate = useNavigate();
  const params = useParams();
  const token = params.token;
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      checkToken(token);
    }
  }, [navigate]);

  async function checkToken(token) {
    const isValid = await api
      .get("/authorization/token")
      .then((data) => {
        return true;
      })
      .catch((err) => {
        if (err.response.status == 401) {
          return false;
        }
      });
    if (isValid == true) {
      setIsAuthenticated(true);
      let expirationDate = new Date();
      expirationDate.setHours(expirationDate.getHours() + 24);
      localStorage.setItem("expiration", expirationDate.toString());
      navigate("/home");

      return true;
    } else if (isValid == false) {
      localStorage.removeItem("token");
    }
  }

  const DesktopLoginDisplay = () => {
    return (
      <>
        <Blue_rectangle />
        <White_rectangle />
      </>
    );
  };

  return (
    <div className="Login">
      {isDesktop ? <DesktopLoginDisplay /> : <White_rectangle />}
    </div>
  );
}

// Login.propTypes = {
//     setToken: PropTypes.func.isRequired
// }
export default Login;
