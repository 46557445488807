import React from "react";
import "./Footer.scss";
import footerLogo from "../../assets/MECALIFE-LOGO-NOM-FD_BLsNR 1.png";
import phoneIcon from "../../assets/phoneIcon.svg";
import mailIcon from "../../assets/mailIcon.svg";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  function Logout() {
    localStorage.removeItem("token");
    navigate("/login", { replace: true });
  }

  return (
    <div className="Footer">
      <div className="dark-blue">
        <div className="footer-top">
          <img src={footerLogo} alt="" />
          <div className="logout">
            <h4 onClick={() => Logout()}>Deconnexion</h4>
          </div>
        </div>
      </div>
      <div className="footer-mid-wrapper">
        <div className="footer-mid">
          {/* <ul>
            <li>Nouveaux véhicules</li>
            <li>Rechercher un véhicule</li>
            <li>Mon compte</li>
            <li>Favoris</li>
          </ul> */}
          {/* <hr className="footer-mid-separator" /> */}
          <ul className="footer-contact-wrapper">
            <li className="footer-contact">
              <img src={phoneIcon} alt="" />
              05.82.28.90.00
            </li>
            <li className="footer-contact">
              <img src={mailIcon} alt="" />
              contact@mecalife.com
            </li>
          </ul>
        </div>
      </div>
      <div className="dark-blue">
        <div className="footer-bottom">
          <p>CGU</p>
          <p>Mentions légales</p>
          <p>Politique de protection de données</p>
        </div>
      </div>
    </div>
  );
}
