import logoNoirMecalife from "../../assets/MECALIFE-LOGO-NOM-FD_NRsBL.png";

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { atom, useAtom } from "jotai";
import validator from "validator";
import { isAuthenticatedAtom, isExpiredAtom } from "../../App.js";
import warningIcon from "../../assets/warningIcon.svg";
export const emailAtom = atom(null);

export default function Form_connect() {
  const [login, setLogin] = useAtom(emailAtom);
  const [password, setPassword] = useState();
  const [isAuthenticated, setIsAuthenticated] = useAtom(isAuthenticatedAtom);
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.setItem("loginStorage", login);
  }, [login]);

  async function loginUser() {
    const headers = {
      login: login,
      password: password,
    };

    await axios
      .post(
        process.env.REACT_APP_BASE_URI + "/authorization/token",
        { login },
        { headers }
      )
      .then((response) => {
        document.getElementById("wrong_login").style.display = "none";
        document.getElementById("password").style.border = "none";
        document.getElementById("email").style.border = "none";
        localStorage.setItem("token", response.data.key);
        localStorage.setItem("expiration", response.data.expiration);
        setIsAuthenticated(response.data.key);
        navigate("/home", { replace: true });
        return response.data;
      })
      .catch((error) => {
        document.getElementById("wrong_login").style.display = "flex";
        document.getElementById("password").style.border = "1px solid red";
        document.getElementById("email").style.border = "1px solid red";
        return error;
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = await loginUser();
  };

  function validateEmail() {
    const alert = document.getElementById("email_alert");
    const Email = document.getElementById("email");

    if (validator.isEmail(login, { require_tld: false })) {
      document.getElementById("email_alert").style.display = "none";
      Email.setAttribute("style", "border: none;");
    } else {
      alert.setAttribute("style", "display: flex;");
      Email.setAttribute("style", "border: 1px solid red;");
    }
  }

  return (
    <div className="FormConnect">
      <form className="form" onSubmit={handleSubmit}>
        <div className="top">
          <img
            className="logo_login"
            src={logoNoirMecalife}
            alt={"logo_meca"}
          />
        </div>

        <div className="middle">
          <div className="wrong_login" id="wrong_login">
            Mauvais identifiant et/ou mot de passe
          </div>
          <div className="email_alert" id="email_alert">
            Format invalide
          </div>
          <input
            type="email"
            className="email"
            placeholder="Adresse mail"
            name="email"
            id="email"
            maxLength="300"
            minLength="3"
            onChange={(e) => setLogin(e.target.value)}
            autoComplete="off"
          />
          <input
            type="password"
            data-testid="password"
            id="password"
            className="password"
            placeholder="Mot de passe"
            maxLength="999"
            minLength="4"
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <a className="forgot-password" href="/login/frgtpswd">
            Mot de passe oublié ?
          </a> */}
        </div>

        <div className="bottom">
          <div className="connect-container">
            <button
              type="submit"
              data-testid="button_connect"
              className="primary-orange"
            >
              Se connecter
            </button>
            {/* <div className="new-here-div">
            <p className="new_here">Nouveau ici ?</p>
            <a className="register" href="/login/register">
              Créer un compte.
            </a>
          </div> */}

            <div className="id-warning">
              <img src={warningIcon} alt="" />
              <p>
                Les indentifiants de connexion sont ceux de
                <span> Mecavin pro</span>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
