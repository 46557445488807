import React from "react";
import "./ExpertComment.scss";

import { SectionTitle } from "../../index.js";

export default function ExpertComment({ comment }) {
  return (
    <div className="ExpertComment">
      <SectionTitle title="Commentaires" />
      <div className="comment-section">
        <div
          className="comment-content"
          dangerouslySetInnerHTML={{ __html: comment }}
        ></div>
      </div>
    </div>
  );
}
