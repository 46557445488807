import React, { useEffect } from "react";
import "./Header.scss";
import { Link, useNavigate } from "react-router-dom";
import { atom, useAtom } from "jotai";

import logoMecalife from "../../assets/headerMecalife.svg";
import goToHome from "../../assets/goToHome.svg";
import goToFavs from "../../assets/goToFavs.svg";
import goToCopilot from "../../assets/goToCopilot.svg";

import { companyDataAtom } from "../../App.js";

export default function Header({ onWhichPage }) {
  const [companyData, setCompanyData] = useAtom(companyDataAtom);
  const navigate = useNavigate();

  return (
    <div className="Header">
      <div className="logo-wrapper">
        <Link
          to="/home"
          style={{ textDecoration: "none" }}
          aria-label="Accueil"
        >
          <img
            className="headerLogo"
            src={logoMecalife}
            alt="Logo Mecalife - Retour à l'accueil"
          />
        </Link>
      </div>

      <div className="icons">
        <Link
          to="/home/favorites"
          className={
            onWhichPage === "fav"
              ? "current-page header-nav-btns"
              : "header-nav-btns"
          }
        >
          <img src={goToFavs} alt="Mes favoris" />
        </Link>

        <Link
          to="/copilot"
          className={
            onWhichPage === "copilot"
              ? "current-page header-nav-btns"
              : "header-nav-btns"
          }
        >
          <img src={goToCopilot} alt="Copilot" />
        </Link>

        <Link
          to="/home"
          className={
            onWhichPage === "home"
              ? "current-page header-nav-btns"
              : "header-nav-btns"
          }
        >
          <img src={goToHome} alt="Retour à l'accueil" />
        </Link>
      </div>

      {companyData != [] && companyData[0] != undefined && (
        <p className="connected-label">
          connecté en tant que : <span>{companyData[0].name}</span>
        </p>
      )}
    </div>
  );
}
