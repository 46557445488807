import React, { useEffect, useState } from "react";
import api from "../../api.js";
import "./DeposerOffre.scss" ;

export default function DeposerOffre({ auctionInfoProps, linksInfoProps }) {

  const [company, setCompany] = useState(null);
  const [user, setUser] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);

  function concatUrls(arrayOfObjects) {
    if (!Array.isArray(arrayOfObjects) || arrayOfObjects.length === 0) {
      return "";
    }
    const urls = arrayOfObjects.map(obj => obj.url);
    const concatenatedUrls = urls.join(',');
    return concatenatedUrls;
  }
  
  async function getUserInfo(){
    const response = await api
        .get(process.env.REACT_APP_BASE_URI + "/user")
        .catch((e) => {
          console.error(e);
        });  
        setUser(response.data);
  }

  async function getCompanyInfo(){
    const response = await api
        .get(process.env.REACT_APP_BASE_URI + "/user/company")
        .catch((e) => {
          console.error(e);
        });
        setCompany(response.data[0]);
  }

  function displayLandbot() {
    if (dataFetched && user && company) {
      const script = document.createElement("script");
      script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";
      script.async = true;

      script.addEventListener("load", () => {
          const myLandbot = new window.Landbot.Container({
            container: "#landbotOffer",
            customData : {
              siren: company.siren,
              tel_entreprise: company.phone,
              email_entreprise: company.mail,
              first_name: user.firstName,
              last_name: user.lastName,
              auction_id: auctionInfoProps.auction.auctionId,
              vin: auctionInfoProps.auction.vin,
              mecalife_id: auctionInfoProps.auction.mecalifeId,
              created_at: auctionInfoProps.auction.createdAt,
              start_auction: auctionInfoProps.auction.startAuction,
              end_auction: auctionInfoProps.auction.endAuction,
              ask_price: auctionInfoProps.auction.askPrice,
              start_price: auctionInfoProps.auction.startPrice,
              seller_id: auctionInfoProps.auction.sellerId,
              id_ninox: auctionInfoProps.auction.idNinox,
              on_sale: auctionInfoProps.auction.onSale,
              type_sale: auctionInfoProps.auction.typeSale,
              auction_brand: auctionInfoProps.auctionInfo.brand,
              auction_critair: auctionInfoProps.auctionInfo.critair,
              auction_date_prod: auctionInfoProps.auctionInfo.dateProd,
              auction_description: auctionInfoProps.auctionInfo.description,
              auction_door_number: auctionInfoProps.auctionInfo.doorNumber,
              auction_emission_co2: auctionInfoProps.auctionInfo.emissionCo2,
              auction_fees: auctionInfoProps.auctionInfo.fees,
              auction_fees_mecalife: auctionInfoProps.auctionInfo.feesMecalife,
              auction_finition: auctionInfoProps.auctionInfo.finition,
              auction_fuel_type: auctionInfoProps.auctionInfo.fuelType,
              auction_gearbox: auctionInfoProps.auctionInfo.gearbox,
              auction_horse_power: auctionInfoProps.auctionInfo.horsePower,
              auction_interior: auctionInfoProps.auctionInfo.interior,
              auction_location: auctionInfoProps.auctionInfo.location,
              auction_mecavin_report_url: auctionInfoProps.auctionInfo.mecavinReportUrl,
              auction_mileage: auctionInfoProps.auctionInfo.mileage,
              auction_model: auctionInfoProps.auctionInfo.model,
              auction_motor: auctionInfoProps.auctionInfo.motor,
              auction_note_mecavin: auctionInfoProps.auctionInfo.noteMecavin,
              auction_options_price: auctionInfoProps.auctionInfo.optionsPrice,
              auction_paint: auctionInfoProps.auctionInfo.paint,
              auction_seat_number: auctionInfoProps.auctionInfo.seatNumber,
              auction_title: auctionInfoProps.auctionInfo.title,
              auction_transmission: auctionInfoProps.auctionInfo.transmission,
              auction_vehicle_price: auctionInfoProps.auctionInfo.vehiclePrice,
              auction_year: auctionInfoProps.auctionInfo.year,
              picture_public_id: auctionInfoProps.auctionPicture?.PublicId,
              picture_url: auctionInfoProps.auctionPicture?.Url,
              picture_url_modified: auctionInfoProps.auctionPicture?.UrlModified,
              picture_created_at: auctionInfoProps.auctionPicture?.createdAt,
              picture_updated_at: auctionInfoProps.auctionPicture?.updatedAt,
              links: concatUrls(linksInfoProps),
            },
            configUrl: "https://storage.googleapis.com/landbot.pro/v3/H-1668808-9KX0JHN92L5TIC13/index.json",
          });
      });

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }

  useEffect(() => {
    getCompanyInfo();
    getUserInfo();
    setDataFetched(true);
  }, []);

  useEffect(() => {
      displayLandbot();
  }, [user, company,dataFetched]);

  return (
      <div className="landbotOffer" id="landbotOffer"></div>
  );
}

