import React from "react";
import "./AuctionTitle.scss";

export default function AuctionTitle({ title }) {
  return (
    <div className="AuctionTitle">
      <h2>{title}</h2>
    </div>
  );
}
