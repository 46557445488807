import React from "react";
import "./Loader.scss";

export default function Loader() {
  return (
    <div className="Loader">
      <h1>Chargement</h1>
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
