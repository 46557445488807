import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import { Link } from "react-router-dom";
import api from "../../api.js";

import "./HomeCarCard.scss";

import motorIcon from "../../assets/motor.svg";
import fuelIcon from "../../assets/fuel.svg";
import gearboxIcon from "../../assets/gearbox.svg";
import kmIcon from "../../assets/km.svg";
import powerIcon from "../../assets/horsePower.svg";
import productionDateIcon from "../../assets/productionDate.svg";
import testImg from "../../assets/testPhoto.png";

import { FavorisElement, Timer } from "../index.js";

import { isTabletAtom } from "../../App.js";

export function changeTextFormat(text) {
  return `${text.slice(0, 1)}${text.slice(1).toLowerCase()}`;
}

export default function HomeCarCard({ carData, handleParentTimeOver, index }) {
  const [isTablet, setIsTablet] = useAtom(isTabletAtom);

  const { auction, auctionInfo, auctionPicture, biggestOffer, followStatus } =
    carData;

  const changeDateFormat = (date) => {
    return `${date.slice(8, 10)} / ${date.slice(5, 7)} / ${date.slice(0, 4)}`;
  };

  /**
   * Cette fonction est appellé par le composant enfant Timer.
   * Elle appelle la fonction du composant Home
   * Elle retourne au composant Home l'auctionId de la l'objet qui doit
   * être enlevé de la liste des composant à afficher car le timer est à 0.
   */
  const handleTimeOver = () => {
    handleParentTimeOver(auctionInfo.auctionId);
  };

  const blurDivs = document.querySelectorAll(".blur-load");
  blurDivs.forEach((div) => {
    const img = div.querySelector("img");

    const loaded = () => {
      div.classList.add("loaded");
    };

    if (img.complete) {
      loaded();
    } else {
      img.addEventListener("load", loaded);
    }
  });

  function MobileCardLayout() {
    return (
      <div className="car-data-wrapper">
        <div className="car-data-wrapper-column">
          <p className="car-data">
            <img src={motorIcon} alt="" />
            {auctionInfo.motor ? auctionInfo.motor : "Motor"}
          </p>
          <p className="car-data">
            <img src={gearboxIcon} alt="" />
            {auctionInfo.gearbox
              ? changeTextFormat(auctionInfo.gearbox)
              : "Gearbox"}
          </p>
          <p className="car-data">
            <img src={kmIcon} alt="" />
            {auctionInfo.mileage ? `${auctionInfo.mileage} km` : "000 km"}
          </p>
        </div>

        <div className="car-data-wrapper-column">
          <p className="car-data">
            <img src={powerIcon} alt="" />
            {auctionInfo.horsePower ? `${auctionInfo.horsePower}CH` : "000CH"}
          </p>
          <p className="car-data">
            <img src={fuelIcon} alt="" />
            {auctionInfo.fuelType
              ? changeTextFormat(auctionInfo.fuelType)
              : "Fuel"}
          </p>
          <p className="car-data">
            <img src={productionDateIcon} alt="" />
            {auctionInfo.dateProd
              ? changeDateFormat(auctionInfo.dateProd)
              : "00 / 00 / 0000"}
          </p>
        </div>
      </div>
    );
  }

  function TabletCardLayout() {
    return (
      <div className="car-data-wrapper">
        <div className="car-data-wrapper-row">
          <p className="car-data">
            <img src={motorIcon} alt="" />
            {auctionInfo.motor ? auctionInfo.motor : "Motor"}
          </p>
          <p className="car-data">
            <img src={powerIcon} alt="" />
            {auctionInfo.horsePower ? `${auctionInfo.horsePower}CH` : "000CH"}
          </p>
          <p className="car-data">
            <img src={fuelIcon} alt="" />
            {auctionInfo.fuelType
              ? changeTextFormat(auctionInfo.fuelType)
              : "Fuel"}
          </p>
        </div>

        <div className="car-data-wrapper-row">
          <p className="car-data">
            <img src={gearboxIcon} alt="" />
            {auctionInfo.gearbox
              ? changeTextFormat(auctionInfo.gearbox)
              : "Gearbox"}
          </p>
          <p className="car-data">
            <img src={kmIcon} alt="" />
            {auctionInfo.mileage ? `${auctionInfo.mileage} km` : "000 km"}
          </p>
          <p className="car-data">
            <img src={productionDateIcon} alt="" />
            {auctionInfo.dateProd
              ? changeDateFormat(auctionInfo.dateProd)
              : "00 / 00 / 0000"}
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="HomeCarCard">
      {auctionInfo && (
        <FavorisElement
          auctionId={auctionInfo.auctionId}
          followStatus={followStatus}
        ></FavorisElement>
      )}
      {/* <div className="new-label">NEW</div> */}
      <Link
        to={`details/${auctionInfo.auctionId}`}
        state={{ carData: carData, index: index }}
      >
        <div
          className="blur-load"
          style={{
            backgroundImage:
              auctionPicture && auctionPicture.url20px != undefined
                ? `url(${auctionPicture.url20px})`
                : "none",
            height: "245px",
          }}
        >
          <img
            className="car-img"
            loading="lazy"
            src={
              auctionPicture
                ? auctionPicture.url600px
                  ? auctionPicture.url600px
                  : auctionPicture.UrlModified
                : testImg
            }
            alt="Miniature de la photo du véhicule"
          ></img>
        </div>
      </Link>
      <div className="card-bottom-part">
        <Link
          to={`details/${auctionInfo.auctionId}`}
          state={{ carData: carData }}
        >
          <h2
            className={
              auctionInfo.title.length < 30
                ? "car-name bigger-text"
                : "car-name"
            }
          >
            {auctionInfo.title ? auctionInfo.title : "NOM DE LA VOITURE"}
          </h2>
        </Link>

        {isTablet ? <TabletCardLayout /> : <MobileCardLayout />}

        <div className="hcc-bottom-section">
          <Timer
            date={auction.endAuction}
            handleTimeOver={handleTimeOver}
          ></Timer>
          <Link
            to={`details/${auctionInfo.auctionId}`}
            state={{ carData: carData }}
          >
            <button className="see-more">Voir plus</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
