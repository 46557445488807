import React, { useEffect, useState } from "react";
import { atom, useAtom } from "jotai";
import { useParams } from "react-router-dom";

import "./ModalDetails.scss";
import Select from "react-select";
import goBackCrossIcon from "../../../assets/goBackCrossIcon.svg";
import axios from "axios";

import {
  modalIsOpenAtom,
  listCompaniesAtom,
} from "../PictureCard/PictureCTA/PictureCTA.js";
import api from "../../../api.js";

const priceAtom = atom(0);

const selectedCompanyListAtom = atom();

export default function ModalDetails() {
  const { auctionId } = useParams();
  const [modalIsOpen, setModalIsOpen] = useAtom(modalIsOpenAtom);
  const [price, setPrice] = useAtom(priceAtom);
  const [companyList, setCompanyList] = useAtom(listCompaniesAtom);
  const [selectedCompanyList, setSelectedCompanyList] = useAtom(
    selectedCompanyListAtom
  );
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (modalIsOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalIsOpen]);

  const handleValidateOffer = async () => {
    if (!selectedCompanyList || !selectedCompanyList.value || !price) {
      setErrorMessage("Veuillez sélectionner une entreprise et entrer un prix.");
    } else {
      const data = {
        siren: selectedCompanyList.value,
        auctionId: auctionId,
        price,
      }
      const now = new Date();
      const formattedDate = now.toISOString()
      const dataNinox = {
        auctionId: auctionId,
        idNinox: selectedCompanyList.idNinox,
        phone: selectedCompanyList.phone,
        mail: selectedCompanyList.mail,
        name: selectedCompanyList.label,
        siren: selectedCompanyList.value,
        price,
        date: formattedDate
      }
      const response = await api.post('/auction/offers', data).catch((err) => {
        setErrorMessage("Vous avez déjà déposé le même prix de " + price);
      })

      if (process.env.REACT_APP_ENV == "PROD") {
        axios.post(process.env.REACT_APP_WEBHOOK_OFFER, dataNinox)
      }

      if (response) {
        // Reset fields
        setSelectedCompanyList(null);
        setPrice(0);
        setErrorMessage(null);
        setModalIsOpen(false);
      }

    }
  };

  return (
    <div
      className={
        modalIsOpen ? "ModalDetails" : "ModalDetails modal-details-hidden"
      }
    >
      <div className="modal-wrapper">
        <button
          onClick={() => {
            setSelectedCompanyList(null);
            setPrice(0);
            setErrorMessage(null);
            setModalIsOpen(false);
          }}
          className="close-modal"
        >
          <img src={goBackCrossIcon} alt="ferme la fenêtre modale" />
        </button>
        <h2>Déposer une offre</h2>
        <Select
          className="fv-selector"
          value={selectedCompanyList}
          onChange={setSelectedCompanyList}
          options={companyList}
          isClearable
          isSearchable
          placeholder="Choisir mon entreprise"
          noOptionsMessage={() => "Pas de résultat"}
          styles={{
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              color: "black",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              minHeight: "42px",
              backgroundColor: "#F5F5F5",
              outline: "none",
              border: state.isFocused
                ? "1px solid #2684FF"
                : "1px solid #F5F5F5",
            }),
          }}
        />
        <input
          className="bid-amount"
          type="number"
          name=""
          id=""
          placeholder="10000"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <button className="validate-offer" onClick={handleValidateOffer}>
          Valider mon offre
        </button>
      </div>
    </div>

  )
}
