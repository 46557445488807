import "./OffersDetails.scss";
import api from "../../../api.js";
import React, { useEffect } from "react";
import { atom, useAtom } from "jotai";

import {
  listOffersAtom,
  askPriceAtom,
  userCompaniesListAtom,
} from "../DetailsV2.js";
import { reloadAtom } from "../MakeAnOfferModal/MakeAnOfferModal.js";
export const stockOffersDetailsAtom = atom([]);
export const userCompanyAtom = atom([]);

export const displayOffersAtom = atom([]);
export const maxOfferAtom = atom({});

export const equalityAtom = atom(false);
export const equalitySirenAtom = atom([]);

export default function OffersDetails() {
  const [stockOffersDetails, setStockOffersDetails] = useAtom(listOffersAtom);
  const [reload, setReload] = useAtom(reloadAtom);
  const [askPrice, setAskPrice] = useAtom(askPriceAtom);
  const [maximumOffer, setMaximumOffer] = useAtom(maxOfferAtom);
  const [userCompany, setUserCompany] = useAtom(userCompanyAtom);
  const [userCompaniesList, setUserCompanyList] = useAtom(
    userCompaniesListAtom
  );
  const [displayOffers, setDisplayOffers] = useAtom(displayOffersAtom);

  const [equality, setEquality] = useAtom(equalityAtom);
  const [equalitySiren, setEqualitySiren] = useAtom(equalitySirenAtom);

  useEffect(() => {
    if (reload == true) {
      setReload(false);
      sortAndDisplayOffers();
    }
  }, [reload]);

  async function sortAndDisplayOffers() {
    let filteredOffers = stockOffersDetails.filter(
      (obj) => obj.offers.price != 0
    );
    if (filteredOffers.length != 0) {
      let sortedOffers = filteredOffers
        .flatMap((obj) => {
          const company = obj.company;
          const offers = obj.offers;
          return {
            company: company,
            auctionId: offers.auctionId,
            price: offers.price,
            createdAt: offers.createdAt,
            siren: offers.siren,
          };
        })
        .sort((a, b) => b.price - a.price);

      const maxOffers = sortedOffers[0];
      let sirenOfFirst = maxOffers.siren;

      setMaximumOffer(maxOffers);

      let priceOfSecond;
      let sirenOfSecond = "0";

      let priceOfThird = 0;
      let sirenOfThird = "0";

      if (sortedOffers.length > 1) {
        priceOfSecond = Number(sortedOffers[1].price);
        sirenOfSecond = sortedOffers[1].siren;
      } else {
        priceOfSecond = Number(askPrice);
      }

      if (sortedOffers.length > 2) {
        priceOfThird = Number(sortedOffers[2].price);
      }
      if (
        Number(maxOffers.price) - 100 > priceOfSecond &&
        sirenOfFirst != sirenOfSecond
      ) {
        let offersVickrey = {
          auctionId: maxOffers.auctionId,
          company: maxOffers.company,
          createdAt: maxOffers.createdAt,
          siren: maxOffers.siren,
        };
        if (sortedOffers.length > 1) {
          offersVickrey.price = Number(sortedOffers[1].price) + 100;
        } else {
          offersVickrey.price = Number(askPrice) + 100;
        }
        sortedOffers.shift();
        sortedOffers.unshift(offersVickrey);
        const data = {
          siren: offersVickrey.siren,
          auctionId: offersVickrey.auctionId,
          price: offersVickrey.price,
          createdAt: maxOffers.createdAt,
        };
        setEquality(false);

        const response = await api.post("/auction/offers", data);
      } else if (Number(maxOffers.price) === priceOfSecond) {
        setEquality(true);
        const equalitySiren = [maxOffers.siren, sirenOfSecond];
        setEqualitySiren(equalitySiren);
      } else if (
        sirenOfFirst == sirenOfSecond &&
        priceOfSecond == priceOfThird &&
        sirenOfSecond != sirenOfThird
      ) {
        setEquality(false);
        let offersVickrey = {
          auctionId: maxOffers.auctionId,
          company: maxOffers.company,
          createdAt: maxOffers.createdAt,
          siren: maxOffers.siren,
        };
        if (sortedOffers.length > 1) {
          offersVickrey.price = Number(sortedOffers[1].price) + 100;
        } else {
          offersVickrey.price = Number(askPrice) + 100;
        }
        sortedOffers.shift();
        sortedOffers.unshift(offersVickrey);
        const data = {
          siren: offersVickrey.siren,
          auctionId: offersVickrey.auctionId,
          price: offersVickrey.price,
          createdAt: maxOffers.createdAt,
        };

        const response = await api
          .post("/auction/offers", data)
          .catch((err) => {
            console.log(err);
          });
      } else if (
        Number(maxOffers.price) - 100 == priceOfSecond &&
        sirenOfFirst != sirenOfSecond
      ) {
      } else {
        setEquality(false);
        sortedOffers.shift();
      }
      setDisplayOffers(sortedOffers);
    } else {
      setDisplayOffers(filteredOffers);
    }
  }

  function DisplayOffersDetails() {
    return displayOffers.map((data, index) => {
      const price = data.price;
      //slice pour selectionner la date
      const dateCreatedAt = new Date(data.createdAt);

      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: "Europe/Paris",
      };

      const formattedTimeDMYDisplay = dateCreatedAt
        .toLocaleString("fr-FR", options)
        .split(" ")[0];
      const formattedTimeHMSDisplay = dateCreatedAt
        .toLocaleString("fr-FR", options)
        .split(" ")[1];
      const companyName = data.company.name;

      function getDateDisplay() {
        // on recup la date actuel
        const now = new Date();
        const dayDifference = now.getDay() - dateCreatedAt.getDay();
        const monthDifference = now.getMonth() - dateCreatedAt.getMonth();

        if (monthDifference == 0) {
          if (dayDifference == 0) {
            return formattedTimeHMSDisplay;
          } else if (dayDifference == 1) {
            return "Hier";
          }
        }
        return formattedTimeDMYDisplay;
      }

      return (
        <div
          className={
            index !== 0 && userCompaniesList.includes(companyName)
              ? "offers-details-user"
              : index !== 0 && userCompaniesList.includes(companyName) === false
              ? "offers-details"
              : index === 0 &&
                userCompaniesList.includes(companyName) &&
                equality
              ? "offers-details-user-equality"
              : index === 0 &&
                userCompaniesList.includes(companyName) === false &&
                equality
              ? "offers-details-user-equality"
              : index === 0 &&
                userCompaniesList.includes(companyName) &&
                !equality
              ? "offers-details-user-winning"
              : index === 0 &&
                userCompaniesList.includes(companyName) === false &&
                !equality
              ? "offers-details-user-losing"
              : ""
          }
        >
          <div className="companyName">
            {userCompaniesList.includes(companyName) ? "Vous" : "Anonyme"}
          </div>
          <div className="price-and-CreatedAt-wrapper">
            <div className="createdAt">{getDateDisplay()}</div>
            <div>{price} €</div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="OffersDetails">
      <div className="offers-wrapper">
        <DisplayOffersDetails />
        <div className="grey-transition"></div>
        <div className="offers-details">
          {" "}
          <span className="start-price">Prix de départ</span>{" "}
          <div className="ask-price">{askPrice} €</div>{" "}
        </div>
      </div>
    </div>
  );
}
