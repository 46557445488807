import React from "react";
import { useAtom } from "jotai";
import "./FindVehicule.scss";
import Select from "react-select";

// IMPORT DES ATOMS JOTAI NÉCESSAIRES AUX STATES PARTAGÉS //
import {
  brandListAtom,
  modelListAtom,
  selectedBrandListAtom,
  selectedModelListAtom,

} from "../Home/Home.js";

import { auctionListAtom, filteredAuctionListAtom } from "../../App.js";

export default function FindVehicule() {
  // STATES //
  const [ourLatestCars, setOurLatestCars] = useAtom(auctionListAtom);
  const [filteredAuctionList, setFilteredAuctionList] = useAtom(
    filteredAuctionListAtom
  );

  const [brandList, setBrandList] = useAtom(brandListAtom);
  const [modelList, setModelList] = useAtom(modelListAtom);
  const [selectedBrandList, setSelectedBrandList] = useAtom(
    selectedBrandListAtom
  );
  const [selectedModelList, setSelectedModelList] = useAtom(
    selectedModelListAtom
  );

  // FUNCTIONS //

  const filterAuctions = () => {
    let unformattedSelectedBrandList =
      turnIntoNonFormattedList(selectedBrandList);
    let unformattedSelectedModelList =
      turnIntoNonFormattedList(selectedModelList);

    let filtered = [];
    switch (true) {
      case unformattedSelectedBrandList.length === 0 &&
        unformattedSelectedModelList.length === 0:
        setFilteredAuctionList(ourLatestCars);
        break;
      case unformattedSelectedBrandList.length !== 0 &&
        unformattedSelectedModelList.length === 0:
        filtered = ourLatestCars.filter((auction) => {
          return unformattedSelectedBrandList.includes(
            auction.auctionInfo.brand
          );
        });
        setFilteredAuctionList(filtered);
        break;
      case unformattedSelectedBrandList.length === 0 &&
        unformattedSelectedModelList.length !== 0:
        filtered = ourLatestCars.filter((auction) => {
          return unformattedSelectedModelList.includes(
            auction.auctionInfo.model
          );
        });
        setFilteredAuctionList(filtered);
        break;
      case unformattedSelectedBrandList.length !== 0 &&
        unformattedSelectedModelList.length !== 0:
        filtered = ourLatestCars.filter((auction) => {
          return (
            unformattedSelectedBrandList.includes(auction.auctionInfo.brand) &&
            unformattedSelectedModelList.includes(auction.auctionInfo.model)
          );
        });
        setFilteredAuctionList(filtered);
        break;
    }
  };

  let turnIntoNonFormattedList = (formattedList) => {
    return formattedList.map((element) => element.label);
  };

  return (
    <div className="FindVehicule">
      <h2>Trouvez le véhicule que votre client recherche.</h2>
      <div className="fv-btns-wrapper">
        <Select
          className="fv-selector"
          value={selectedBrandList}
          onChange={setSelectedBrandList}
          options={brandList}
          isMulti
          isClearable
          isSearchable
          placeholder="Marque"
          noOptionsMessage={() => "Pas de résultat"}
          styles={{
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              color: "black",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              minHeight: "42px",
            }),
          }}
        />
        <Select
          className="fv-selector"
          value={selectedModelList}
          onChange={setSelectedModelList}
          options={modelList}
          isMulti
          isClearable
          isSearchable
          placeholder="Modèle"
          noOptionsMessage={() => "Pas de résultat"}
          styles={{
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              color: "black",
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              minHeight: "42px",
            }),
          }}
        />

        <button className="search-btn" onClick={filterAuctions}>
          Rechercher
        </button>
      </div>
    </div>
  );
}
