import React, { useEffect, useState, useRef } from "react";
import { atom, useAtom } from "jotai";
import {SectionTitle} from "../index.js"
import { Chart as ChartJs, LinearScale, PointElement, LineElement, Tooltip, Legend } from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import "./Pricing.scss"
import { pricingDataAtom } from "../DetailsV2/DetailsV2.js" ;
import Slider from "../Slider/Slider.js" ;



ChartJs.register( LinearScale, 
                  PointElement, 
                  LineElement, 
                  Tooltip, 
                  Legend, 
                );

export default function Pricing() {

  const [pricingData] = useAtom(pricingDataAtom); 
  const [truncatedData, setTruncatedData] = useState([]);
  const [stepSize, setStepSize] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (pricingData !== null && pricingData.length !== 0) {
      const chartData = pricingData.map(function (item) {
        return { x: item.KILOMETRAGE, y: item.PRIX, link: item.LIEN_ANNONCE, tronquage: item.POURCENTAGE_TRONQUAGE };
      });

      const truncatedData = truncateData(chartData, pricingData[0].POURCENTAGE_TRONQUAGE);
      const stepSize = calculateStepSize(truncatedData);

      setTruncatedData(truncatedData);
      setStepSize(stepSize);
      
    }
    setIsLoading(false);
  }, [pricingData]);

  function truncateData(data,dataPercentage) {
    const percentage = (100 - dataPercentage)/100;
    const sortedData = data.sort((a, b) => a.y - b.y);
    const truncateCount = Math.floor(sortedData.length * percentage);
    const truncatedData = sortedData.slice(truncateCount, sortedData.length - truncateCount);
    return truncatedData;
  }
  
  function formatPrice(value,stepSize){
    if (stepSize!= null && stepSize === 1000){
      if (value >= 1000) {
      return `${(value / 1000).toFixed(0)}k €`;
     }
      return `${value} €`;
    }
    return `${value} €`;
  };
  
  function formatKilometrage(value) {
    return value + " km";
  }
  
  function calculateStepSize(chartData) {
    const maxPrice = Math.max(...chartData.map(item => item.y));
    const minPrice = Math.min(...chartData.map(item => item.y));
    const priceRange = maxPrice - minPrice;

    let stepSize = null;
    if (priceRange >= 2000) {
      stepSize = 1000; 
    }
    return stepSize;
  }

  const formatPriceCallback = value => formatPrice(value, stepSize);



  const optionsScatter = {
    scales: {
      x: {
        title: {
          display: false,
          text: 'Kilometrage'
        },
        beginAtZero: false,
        ticks: {
          callback: formatKilometrage,
        },
      },
      y: {
        title: {
          display: false,
          text: 'Prix'
        },
        beginAtZero: false,
        ticks: {
          callback: formatPriceCallback,
          stepSize: stepSize,
          maxTicksLimit: 8,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
    
  const dataScatter = {
    datasets: [
      {
        label: 'Kilométrage vs Prix',
        data: truncatedData,
        pointRadius: 5,
        backgroundColor: 'rgba(0, 0, 167, 0.3)',
        borderColor: 'rgba(0, 0, 167, 1)',
      },
    ],
  };

  return (
    <div className='Pricing'>
        <SectionTitle title="Pricing"/>
        <div className="chart-container">
        {!isLoading && pricingData !== null && pricingData.length !== 0 ? (
        <>
            <Scatter className="chart" data={dataScatter} options={optionsScatter} /> 
            <Slider truncatedData={truncatedData}/> 
            </>
      ) : (
        <p>Loading...</p>
        )}
        </div>
    </div>
  );
}
  