import React, { useEffect, useState } from "react";
import api from "../../api.js";

import { useAtom, atom, useSetAtom } from "jotai";

export const initializedAtom = atom(false);

export default function Landbot() {

  const [company, setCompany] = useState(null);
  const [initialized, setInitialized] = useAtom(initializedAtom);

  async function getUserInfo(){
    const response = await api
        .get(process.env.REACT_APP_BASE_URI + "/user/company")
        .catch((e) => {
          console.error(e);
        });
        setCompany(response.data[0]);
  }

  function initLandbot() {
    let myLandbot;
    if(!window.Landbot && !initialized && company) {
      var s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.addEventListener('load', function() {
        myLandbot = new window.Landbot.Livechat({
          customData : {
          siren: company.siren,
          name: company.name,
          phone: company.phone,
          mail: company.mail
          },
          configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-1367365-CB5BIO63UKDE5NA5/index.json',
        });
      });
      s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
      var x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);

      setInitialized(true);
   }
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  useEffect(() => {
      initLandbot();
  }, [company, initialized]);

  return null;
}
