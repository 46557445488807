import React, { useEffect } from "react";
import "./MakeAnOfferModal.scss";

import { atom, useAtom } from "jotai";
import Select from "react-select";

import { Timer, OffersDetails, AuctionTitle } from "../../index.js";
import api from "../../../api.js";
import axios from "axios";
import { companyDataAtom } from "../../../App.js";

import closingCross from "../../../assets/closingCross.svg";
import checkIcon from "../../../assets/confirmationIcon.svg";

// atoms Jotai
import {
  isModalOpenAtom,
  listCompaniesAtom,
  askPriceAtom,
  auctionAtom,
  listOffersAtom,
} from "../DetailsV2.js";
import {
  maxOfferAtom,
  equalityAtom,
  equalitySirenAtom,
} from "../OffersDetails/OffersDetails.js";

export const reloadAtom = atom(false);
export const chosenCompanyAtom = atom("");
export const isConfirmedAtom = atom(false);
const offerValueAtom = atom("");

const isCompanyValidAtom = atom(true);
const isOfferValueValidAtom = atom(true);
const errorMessageAtom = atom("");

export default function MakeAnOfferModal({ title, auctionId, endAuction }) {
  //state Jotai
  const [chosenCompany, setChosenCompany] = useAtom(chosenCompanyAtom);
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);
  const [isConfirmed, setIsConfirmed] = useAtom(isConfirmedAtom);
  const [maximumOffer, setMaximumOffer] = useAtom(maxOfferAtom);
  const [companyData, setCompanyData] = useAtom(companyDataAtom);
  const [listCompanies, setListCompanies] = useAtom(listCompaniesAtom);

  const [reload, setReload] = useAtom(reloadAtom);
  const [auction, setAuction] = useAtom(auctionAtom);
  const [offerValue, setOfferValue] = useAtom(offerValueAtom);

  const [askPrice, setAskPriceAtom] = useAtom(askPriceAtom);

  const [isCompanyValid, setIsCompanyValid] = useAtom(isCompanyValidAtom);
  const [isOfferValueValid, setIsOfferValueValid] = useAtom(
    isOfferValueValidAtom
  );

  const [listOffers, setListOffers] = useAtom(listOffersAtom);
  const [errorMessage, setErrorMessage] = useAtom(errorMessageAtom);

  const [equality, setEquality] = useAtom(equalityAtom);

  useEffect(() => {
    if (
      maximumOffer.siren == companyData[0].siren &&
      maximumOffer != undefined &&
      equality
    ) {
      setOfferValue(maximumOffer.price);
    }
  }, [equality]);

  async function postOffer() {
    if (chosenCompany == false || !offerValue) {
      setIsOfferValueValid(false);
      setIsCompanyValid(false);
      setErrorMessage(
        "Veuillez sélectionner une entreprise et entrer une offre."
      );
      return;
    }
    const offerValueNumber = parseInt(offerValue);
    const askPriceNumber = parseInt(askPrice);

    if (offerValueNumber % 100 !== 0) {
      setOfferValue(Math.ceil(offerValueNumber / 100) * 100);
      setIsOfferValueValid(false);
      setErrorMessage(
        "L'offre doit être arrondi à la centaine. Nous l'avons arrondi au dessus veuillez validez votre offre pour la déposer."
      );
      return;
    }

    if (offerValueNumber < askPriceNumber + 100) {
      setIsOfferValueValid(false);
      setErrorMessage(
        "L'offre doit être supérieure ou égale au prix demandé + 100€."
      );
      return;
    }

    const data = {
      siren: chosenCompany.value,
      auctionId: auctionId,
      price: offerValue,
    };

    const now = new Date();
    const formattedDate = now.toISOString();
    const dataNinox = {
      auctionId: auction.auction.auctionId,
      idNinox: chosenCompany.idNinox,
      phone: chosenCompany.phone,
      mail: chosenCompany.mail,
      name: chosenCompany.label,
      siren: chosenCompany.value,
      price: offerValue,
      date: formattedDate,
    };

    const response = await api.post("/auction/offers", data).catch((err) => {
      setErrorMessage("Vous avez déjà déposé le même prix de " + offerValue);
    });

    if (process.env.REACT_APP_ENV == "PROD") {
      axios.post(process.env.REACT_APP_WEBHOOK_OFFER, dataNinox);
    }

    if (response) {
      const tmpListOffers = listOffers;
      const offer = {
        siren: data.siren,
        auctionId: data.auctionId,
        price: offerValue,
        createdAt: formattedDate,
      };
      const dataToPush = { offers: offer, company: chosenCompany };
      tmpListOffers.push(dataToPush);
      setListOffers(tmpListOffers);
      setReload(true);
      setIsConfirmed(true);
    }
  }

  function checkIfUserIsBestOffer() {
    if (
      maximumOffer.siren == companyData[0].siren &&
      maximumOffer != undefined &&
      !equality
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className="MakeAnOfferModal">
      <div className="modal-header">
        <button
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          <p>FERMER</p>
          <img src={closingCross} alt="Fermer la fenêtre" />
        </button>
      </div>

      <main>
        {!isConfirmed ? (
          <section className="modal-form">
            <AuctionTitle title={title} />
            <Select
              className={`modal-selector ${isCompanyValid ? "" : "error"}`}
              value={chosenCompany}
              onChange={(selectedOption) => {
                setChosenCompany(selectedOption);
                setIsCompanyValid(true);
              }}
              options={listCompanies}
              isClearable
              isSearchable
              placeholder="Choisir mon entreprise"
              noOptionsMessage={() => "Pas de résultat"}
              styles={{
                menuList: (baseStyles, state) => ({
                  ...baseStyles,
                  color: "black",
                }),
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  minHeight: "42px",
                  backgroundColor: "#F5F5F5",
                  border: "none",
                }),
              }}
            />
            <div className="offer-amount-section">
              <div className="above-offer-input">
                <p>Votre offre maximale</p>
              </div>
              <input
                className={`offer-input ${isOfferValueValid ? "" : "invalid"} ${
                  checkIfUserIsBestOffer() ? "isBestOffer" : ""
                }`}
                type="number"
                min={0}
                value={
                  checkIfUserIsBestOffer() ? maximumOffer.price : offerValue
                }
                onChange={(event) => {
                  setOfferValue(event.target.value);
                  setIsOfferValueValid(true);
                }}
                placeholder="0"
              />
            </div>
            {(!isOfferValueValid || !isCompanyValid) && (
              <p className="error-message">{errorMessage}</p>
            )}
            <div className="note-vickrey">
              <span>Note :</span> Les enchères Vickrey sont un type d'enchères
              où l'offrant qui fait la plus haute offre remporte l'enchère, mais
              paie le prix de l'offrant qui a fait la deuxième offre la plus
              élevée.
            </div>
            <div className="modal-cta">
              <Timer date={endAuction}></Timer>
              <button
                className={checkIfUserIsBestOffer() ? "disabled" : ""}
                onClick={() => {
                  postOffer();
                }}
                disabled={checkIfUserIsBestOffer()}
                readOnly={checkIfUserIsBestOffer()}
              >
                Valider mon offre
              </button>
            </div>
          </section>
        ) : (
          <section className="confirmation-section">
            <AuctionTitle title={title} />
            <div className="confirmation-recap">
              <p>Entreprise : {chosenCompany.label}</p>
              <p>Offre déposée : {offerValue}</p>
            </div>
            <button
              onClick={() => {
                setIsConfirmed(false);
              }}
            >
              Déposer une nouvelle offre
            </button>
            <div className="confirmation">
              <img src={checkIcon} alt="Offre confirmé" />
              <p>Votre offre à bien été enregistré !</p>
            </div>
          </section>
        )}
        <OffersDetails stockAuctionId={auctionId} />
      </main>
    </div>
  );
}
