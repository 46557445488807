import React from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import "./PhotosComponent.scss";
import goBackArrow from "../../../../assets/goBackArrow.svg";

export default function PhotosComponent() {
  let { state } = useLocation();
  const navigate = useNavigate();

  const handleImageLoad = (index) => {
    if (state && state.selectedPhotoIndex === index) {
      setTimeout(() => {
        const photoElement = document.getElementById(`photo-${index}`);
        if (photoElement) {
          photoElement.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  return (
    <>
      <Helmet>
        {state.photosArray.map((element, index) => (
          <link
            key={element.url1920px ? element.url1920px : element.UrlModified}
            rel="preload"
            as="image"
            href={element.url1920px ? element.url1920px : element.UrlModified}
          />
        ))}
      </Helmet>
      <div className="PhotosComponent">
        <button
          onClick={() => {
            navigate(-1);
          }}
          className="go-back-to-details"
        >
          <img src={goBackArrow} alt="Retourner sur la page détails" />
        </button>
        <div className="photos-gallery">
          {state.photosArray.map((photo, index) => {
            return (
              <Link
                key={index}
                to={index + "/"}
                state={{
                  photo: [photo.UrlModified, photo.url600px, photo.url1920px],
                }}
              >
                <img
                  id={`photo-${index}`}
                  className="Photo-from-photos-component"
                  src={photo.url600px ? photo.url600px : photo.UrlModified}
                  alt="image de la voiture"
                  loading="lazy"
                  onLoad={() => handleImageLoad(index)}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}
