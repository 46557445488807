import React, {useEffect, useState} from "react";
import  "./Slider.scss"

export default function RangeSlider({truncatedData}) {

  const [marks, setMarks] = useState([]);
  const [position, setPosition] = useState(0);
  const [index, setIndex] = useState(0);
  const { medianPrice } = calculatePriceStats(truncatedData);

  function calculatePriceStats(data) {
    const prices = data.map(item => item.y);
    const sortedPrices = prices.sort((a, b) => a - b);
    const minPrice = Math.floor(sortedPrices[0]);
    const maxPrice = Math.floor(sortedPrices[sortedPrices.length - 1]);
  
    let medianPrice;
    const middleIndex = Math.floor(sortedPrices.length / 2);
    if (sortedPrices.length % 2 === 0) {
      medianPrice = Math.floor((sortedPrices[middleIndex - 1] + sortedPrices[middleIndex]) / 2);
    } else {
      medianPrice = Math.floor(sortedPrices[middleIndex]);
    }
    return {
      minPrice,
      maxPrice,
      medianPrice
    };
  }
  

function formatLabel(value){
  return `${value} €`;
};

function generateMarksValues(minPrice, maxPrice) {
    const numValues = 6;
    const range = maxPrice - minPrice;
    const step = range / (numValues - 1);
  
    const marksValues = Array.from({ length: numValues }, (_, i) => {
      const value = minPrice + i * step;
      const label = formatLabel(value); 
  
      return { value, label };
    });
  
    return marksValues;
}
  
function getAveragePricePosition(averagePrice, marks) {
    const positionBlock = [6, 22, 41, 60, 79];
    let position = 0;
    let index = 0;
    for (let i = 0; i < marks.length; i++) {
        
        if (averagePrice >= marks[i].value && averagePrice < marks[i + 1]?.value) {
            const valueRange = marks[i + 1].value - marks[i].value;
            let positionRange = 0;
            if (i === 4) {
                positionRange = 100 - positionBlock[i];
            } else {
                positionRange = positionBlock[i + 1] - positionBlock[i];
           }
            const positionOffset = (averagePrice - marks[i].value) / valueRange;
            position = positionBlock[i] + positionOffset * positionRange;
            index = Math.min(i, positionBlock.length - 1);
            break;
        } else if (i === marks.length - 1 && averagePrice >= marks[i].value) {
            position = 94;
            index = Math.min(i, positionBlock.length - 1);
            break;
        }
    }
    return { position, index };
}


  useEffect(() => {
    const { minPrice, maxPrice } = calculatePriceStats(truncatedData);
    const generatedMarks = generateMarksValues(minPrice, maxPrice);
    const { position: avgPosition, index: avgIndex } = getAveragePricePosition(
      medianPrice,
      generatedMarks
    );

    setMarks(generatedMarks);
    setPosition(avgPosition);
    setIndex(avgIndex);
  }, [truncatedData, medianPrice]);

  return (
    <div className="RangeSlider">
        <div className="Slider">
            <div className="Bloc Gradient0"></div>
            <div className="Bloc Gradient1"></div>
            <div className="Bloc Gradient2"></div>
            <div className="Bloc Gradient3"></div>
            <div className="Bloc Gradient4"></div>

            <div className="Cursor"style={{ left: `${position}%`}}>
                <div className={`PriceRectangleContainer Gradient${index}`}>
                    <div>Prix médian</div><div>{medianPrice} €</div>
                </div>
            </div>
        </div>
        <div className="Marks">
            {marks.map((mark, index) => (
                <div key={index} className="Mark">
                    {mark.label}
                </div>
            ))}
      </div>
    </div>
      
  );
};


