import { useAtom } from "jotai";
import "./HeaderDetails.scss";
import { Timer, AuctionTitle, FavorisElement } from "../index.js";
import backGrey from "../../assets/back-arrow-grey.svg";

import React from "react";
import { useNavigate, Link } from "react-router-dom";

// atoms Jotai
import {
  chosenCompanyAtom,
  isConfirmedAtom,
} from "../DetailsV2/MakeAnOfferModal/MakeAnOfferModal.js";
import { maxOfferAtom } from "../DetailsV2/OffersDetails/OffersDetails.js";
import { isModalOpenAtom } from "../DetailsV2/DetailsV2.js";
import previousArrow from "../../assets/previous-arrow.svg";
import nextArrow from "../../assets/next-arrow.svg";

export default function HeaderDetails({
  title,
  endAuction,
  follow,
  auctionId,
  filteredAuctionList,
}) {
  // state Jotai
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);

  const [chosenCompany, setChosenCompany] = useAtom(chosenCompanyAtom);
  const [isConfirmed, setIsConfirmed] = useAtom(isConfirmedAtom);
  const [maxOffers, setMaxOffers] = useAtom(maxOfferAtom);

  const navigate = useNavigate();
  let actualIndex;

  filteredAuctionList.forEach((element, index) => {
    if (element.auction.auctionId === auctionId) {
      actualIndex = index;
    }
  });

  const goNextGoBackArrow = (value) => {
    let auctionPosition;
    if (value === "previous" && actualIndex > 0) {
      actualIndex--;
      auctionPosition = filteredAuctionList[actualIndex].auction.auctionId;
    }

    if (value === "next" && actualIndex < filteredAuctionList.length - 1) {
      actualIndex++;
      auctionPosition = filteredAuctionList[actualIndex].auction.auctionId;
    }
    if (auctionPosition !== undefined) {
      navigate("/home/details/" + auctionPosition);
    } else {
      navigate("/home");
    }
  };
  return (
    <div className="Header-wrapper">
      <div className="HeaderDetails">
        <Link to={"/home"} className="HeaderDetail-back-home-btn">
          <img src={previousArrow} alt="Retour à l'accueil" />
          RETOUR
        </Link>
        <AuctionTitle title={title} />

        <div className="HeaderDetails-fav">
          <FavorisElement
            auctionId={auctionId}
            followStatus={follow}
          ></FavorisElement>
        </div>
        <div className="HeaderDetails-timer">
          <Timer date={endAuction} />
        </div>
        <a
          className="HeaderDetails-offer-btn"
          href="#landbotOffer"

          /*onClick={() => {
            setChosenCompany("");
            setMaxOffers({});
            setIsConfirmed(false);
            setIsModalOpen(true);
          }}*/
        >
          Déposer une offre
        </a>
        <div className="switching-auction">
          <button
            disabled={actualIndex === 0}
            className={
              actualIndex === 0
                ? "switch-auction-btn disabled"
                : "switch-auction-btn"
            }
            onClick={() => goNextGoBackArrow("previous")}
          >
            <img className="navigation-arrow" src={previousArrow} alt="back" />
          </button>
          <button
            disabled={actualIndex === filteredAuctionList.length - 1}
            className={
              actualIndex === filteredAuctionList.length - 1
                ? "switch-auction-btn disabled"
                : "switch-auction-btn"
            }
            onClick={() => goNextGoBackArrow("next")}
          >
            <img className="navigation-arrow" src={nextArrow} alt="next" />
          </button>
        </div>
      </div>
    </div>
  );
}
