import React, { useEffect, useState } from "react";
import "./Timer.scss";
export default function Timer({ date, handleTimeOver,isOptional }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const getTime = () => {
    const time = Date.parse(date) - Date.now();
    const daysLeft = Math.floor(time / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((time / (1000 * 60 * 60)) % 24);
    const minutesLeft = Math.floor((time / 1000 / 60) % 60);
    const secondsLeft = Math.floor((time / 1000) % 60);

    if (time <= 0) {
      if (!isOptional) {
        handleTimeOver();
      }
    } else {
      setDays(daysLeft);
      setHours(hoursLeft);
      setMinutes(minutesLeft);
      setSeconds(secondsLeft);
    }
  };

  const DisplayTimer = () => {
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");
    if (days < 1) {
      return (
        <p>
          {formattedHours}h {formattedMinutes}m
        </p>
      );
    } else if (days == 1) {
      return (
        <div className="equal-to-1-day">
          <p>{days} jour</p>
          <p>
            {formattedHours}h {formattedMinutes}m
          </p>
        </div>
      );
    } else {
      return <p>{days} jours</p>;
    }
  };

  useEffect(() => {
    getTime(date);
    const interval = setInterval(() => getTime(date), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={days < 2 ? "Timer less-than-2-days" : "Timer"}>
      <svg
        width="16"
        height="22"
        viewBox="0 0 16 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.56197 17.5H11.0016M3.38611 1H13.1774C13.6852 1 13.9391 1 14.133 1.10899C14.3036 1.20487 14.4423 1.35785 14.5292 1.54601C14.628 1.75992 14.628 2.03995 14.628 2.6V4.67452C14.628 5.1637 14.628 5.40829 14.5779 5.63846C14.5335 5.84254 14.4602 6.03763 14.3608 6.21657C14.2487 6.4184 14.0919 6.59135 13.7783 6.93726L11.1207 9.86863C10.7617 10.2646 10.5822 10.4627 10.5149 10.691C10.4557 10.8918 10.4557 11.1082 10.5149 11.309C10.5822 11.5373 10.7617 11.7354 11.1207 12.1314L13.7783 15.0627C14.0919 15.4086 14.2487 15.5816 14.3608 15.7834C14.4602 15.9624 14.5335 16.1575 14.5779 16.3615C14.628 16.5917 14.628 16.8363 14.628 17.3255V19.4C14.628 19.9601 14.628 20.2401 14.5292 20.454C14.4423 20.6422 14.3036 20.7951 14.133 20.891C13.9391 21 13.6852 21 13.1774 21H3.38611C2.87837 21 2.6245 21 2.43056 20.891C2.25997 20.7951 2.12128 20.6422 2.03436 20.454C1.93555 20.2401 1.93555 19.9601 1.93555 19.4V17.3255C1.93555 16.8363 1.93555 16.5917 1.98565 16.3615C2.03006 16.1575 2.10333 15.9624 2.20274 15.7834C2.31487 15.5816 2.47167 15.4086 2.78527 15.0627L5.44287 12.1314C5.8019 11.7354 5.98141 11.5373 6.04867 11.309C6.10783 11.1082 6.10783 10.8918 6.04867 10.691C5.98141 10.4627 5.80189 10.2646 5.44287 9.86863L2.78527 6.93726C2.47167 6.59136 2.31487 6.4184 2.20274 6.21657C2.10333 6.03763 2.03006 5.84254 1.98565 5.63846C1.93555 5.40829 1.93555 5.1637 1.93555 4.67452V2.6C1.93555 2.03995 1.93555 1.75992 2.03436 1.54601C2.12128 1.35785 2.25997 1.20487 2.43056 1.10899C2.6245 1 2.87837 1 3.38611 1Z"
          stroke="#F38E36"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <DisplayTimer />
    </div>
  );
}
