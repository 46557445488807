import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAtom, atom } from "jotai";

import "./PictureCardV2.scss";

import pictureIcon from "../../../assets/pictureIcon.svg";

import { picturesAuctionObjectAtom2 } from "../DetailsV2.js";
import { is970pxAtom, is1600pxAtom } from "../../../App.js";

export const preloadPhotosArrayAtom = atom([]);

export default function PictureCardV2({ photosArray }) {
  const [is970px, setIs970px] = useAtom(is970pxAtom);
  const [is1600px, setIs1600px] = useAtom(is1600pxAtom);
  const [picturesAuctionObject, setPicturesAuctionArray] = useAtom(
    picturesAuctionObjectAtom2
  );

  function PictureCardTabletDisplay() {
    return (
      <div className="grid-pictures">
        <div className="nb-pictures">
          <img src={pictureIcon} alt="nombre de photo" />
          <p>{photosArray.length}</p>
        </div>
        <div className="left-photo">
          <Link
            style={{ width: "100%", height: "100%" }}
            to={"photos/"}
            state={{ photosArray: photosArray, selectedPhotoIndex: 0 }}
          >
            <div
              className="blur-load"
              style={{
                backgroundImage:
                  photosArray[0].url20px != undefined
                    ? `url(${photosArray[0].url20px})`
                    : "none",
              }}
            >
              <img
                src={
                  photosArray[0].url600px
                    ? photosArray[0].url600px
                    : photosArray[0].UrlModified
                }
                alt="Photo principale"
              />
            </div>
          </Link>
        </div>

        <div className="right-photos-v2">
          <div className="row">
            <Link
              style={{ width: "100%", height: "100%" }}
              to={"photos/"}
              state={{ photosArray: photosArray, selectedPhotoIndex: 1 }}
            >
              <div
                className="blur-load"
                style={{
                  backgroundImage:
                    photosArray[1].url20px != undefined
                      ? `url(${photosArray[1].url20px})`
                      : "none",
                }}
              >
                <img
                  src={
                    photosArray[1].url600px
                      ? photosArray[1].url600px
                      : photosArray[1].UrlModified
                  }
                  alt="photo 1"
                />
              </div>
            </Link>
          </div>
          <div className="row">
            <Link
              style={{ width: "100%", height: "100%" }}
              to={"photos/"}
              state={{ photosArray: photosArray, selectedPhotoIndex: 2 }}
            >
              <div
                className="blur-load"
                style={{
                  backgroundImage:
                    photosArray[2].url20px != undefined
                      ? `url(${photosArray[2].url20px})`
                      : "none",
                }}
              >
                <img
                  src={
                    photosArray[2].url600px
                      ? photosArray[2].url600px
                      : photosArray[2].UrlModified
                  }
                  alt="photo 2"
                />
              </div>
            </Link>
          </div>
        </div>

        {is1600px && (
          <div className="right-photos-v2">
            <div className="row">
              <Link
                style={{ width: "100%", height: "100%" }}
                to={"photos/"}
                state={{ photosArray: photosArray, selectedPhotoIndex: 3 }}
              >
                <div
                  className="blur-load"
                  style={{
                    backgroundImage:
                      photosArray[3].url20px != undefined
                        ? `url(${photosArray[3].url20px})`
                        : "none",
                  }}
                >
                  <img
                    src={
                      photosArray[3].url600px
                        ? photosArray[3].url600px
                        : photosArray[3].UrlModified
                    }
                    alt="photo 3"
                  />
                </div>
              </Link>
            </div>
            <div className="row">
              <Link
                style={{ width: "100%", height: "100%" }}
                to={"photos/"}
                state={{ photosArray: photosArray, selectedPhotoIndex: 4 }}
              >
                <div
                  className="blur-load"
                  style={{
                    backgroundImage:
                      photosArray[4].url20px != undefined
                        ? `url(${photosArray[4].url20px})`
                        : "none",
                  }}
                >
                  <img
                    src={
                      photosArray[4].url600px
                        ? photosArray[4].url600px
                        : photosArray[4].UrlModified
                    }
                    alt="photo 4"
                  />
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }

  function PictureCardMobileDisplay() {
    return (
      <>
        <div className="picture-wrapper">
          <Link to={"photos/"} state={{ photosArray: photosArray }}>
            <div
              className="blur-load"
              style={{
                backgroundImage:
                  photosArray[0].url20px != undefined
                    ? `url(${photosArray[0].url20px})`
                    : "none",
              }}
            >
              <img
                className="first-picture"
                src={
                  photosArray[0].url600px
                    ? photosArray[0].url600px
                    : photosArray[0].UrlModified
                }
                alt="Photo principale"
              />
            </div>
            <div className="nb-pictures">
              <img src={pictureIcon} alt="nombre de photo" />
              <p>{photosArray.length}</p>
            </div>
          </Link>
        </div>
      </>
    );
  }

  return (
    <div className="Picture-cardV2">
      {photosArray != undefined &&
        photosArray.length > 0 &&
        (is970px ? <PictureCardTabletDisplay /> : <PictureCardMobileDisplay />)}
    </div>
  );
}
