import React from "react";
import { atom, useAtom } from "jotai";
import "./VehiculeDetails.scss";
import { SectionTitle } from "../../index.js";

import fuel from "../../../assets/fuel.svg";
import gearbox from "../../../assets/gearbox.svg";
import horsePower from "../../../assets/horsePower.svg";
import km from "../../../assets/km.svg";
import motor from "../../../assets/motor.svg";
import productionDate from "../../../assets/productionDate.svg";
import transmission from "../../../assets/transmission.svg";
import numberOfSeat from "../../../assets/numberOfSeat.svg";
import numberOfDoors from "../../../assets/numberOfDoors.svg";
import brand from "../../../assets/brand.svg";
import model from "../../../assets/model.svg";
import carFinishing from "../../../assets/carFinishing.svg";
import paint from "../../../assets/paint.svg";
import saddlery from "../../../assets/saddlery.svg";

import critair0 from "../../../assets/critair0.svg";
import critair1 from "../../../assets/critair1.svg";
import critair2 from "../../../assets/critair2.svg";
import critair3 from "../../../assets/critair3.svg";
import critair4 from "../../../assets/critair4.svg";
import critair5 from "../../../assets/critair5.svg";
import noCritair from "../../../assets/pasDeCritair.svg";

import { changeTextFormat } from "../../HomeCarCard/HomeCarCard.js";
import { is970pxAtom, is1600pxAtom } from "../../../App.js";

export default function VehiculeDetails({ carData }) {
  //state Jotai
  const [is970px, setIs970px] = useAtom(is970pxAtom);
  const [is1600px, setIs1600px] = useAtom(is1600pxAtom);

  const { emissionCo2 } = carData;
  const { critair } = carData;

  function formatDate(inputDate) {
    const [year, month, day] = inputDate.split("-");
    const formattedDate = `${day} / ${month} / ${year}`;
    return formattedDate;
  }

  const DisplayCritairImg = () => {
    const alt = "vignette crit'air";
    switch (critair) {
      case "0":
        return <img src={critair0} alt={alt} />;
      case "1":
        return <img src={critair1} alt={alt} />;
      case "2":
        return <img src={critair2} alt={alt} />;
      case "3":
        return <img src={critair3} alt={alt} />;
      case "4":
        return <img src={critair4} alt={alt} />;
      case "5":
        return <img src={critair5} alt={alt} />;
      default:
        return <img src={noCritair} alt={alt} />;
    }
  };

  const DisplayEmissionCo2 = () => {
    let color;
    switch (emissionCo2) {
      case "A":
        color = "#038E1E";
        break;
      case "B":
        color = "#6BB61B";
        break;
      case "C":
        color = "#BFD71D";
        break;
      case "D":
        color = "#FCF31C";
        break;
      case "E":
        color = "#F7BC18";
        break;
      case "F":
        color = "#ED7610";
        break;
      case "G":
        color = "#D3000C";
        break;
      default:
        break;
    }
    return (
      <div className="emission-co2">
        <p>{emissionCo2}</p>
        <svg
          width="82"
          height="30"
          viewBox="0 0 82 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M81.9597 0.181816L14.1391 0.181816L0.459717 15.9879L14.1391 29.8182L81.9597 29.8182V0.181816Z"
            fill={color}
            fillOpacity="0.9"
          />
        </svg>
      </div>
    );
  };

  const FirstDataBlock = () => {
    return (
      <div className="details-vertical-data-block first-block">
        <div className="details-group">
          <img src={carFinishing} alt="Marque" />
          <p>{carData.brand}</p>
        </div>
        <div className="details-group">
          <img src={carFinishing} alt="Modèle du véhicule" />
          <p>{carData.model}</p>
        </div>
        <div className="details-group">
          <img src={carFinishing} alt="Finition" />
          <p>{carData.finition}</p>
        </div>
        <div className="details-group">
          <img src={motor} alt="Type de moteur" />
          <p>{carData.motor}</p>
        </div>
        <div className="details-group">
          <img src={motor} alt="Puissance en chevaux" />
          <p>{carData.horsePower} CH</p>
        </div>
        {!is1600px && (
          <div className="details-group">
            <img src={productionDate} alt="Date de production du véhicule" />
            <p>{formatDate(carData.dateProd)}</p>
          </div>
        )}
      </div>
    );
  };

  const SecondDataBlock = () => {
    return (
      <div className="details-vertical-data-block">
        <div className="details-group">
          <img src={fuel} alt="Type de carburant" />
          <p>{carData.fuelType}</p>
        </div>

        <div className="details-group">
          <img src={gearbox} alt="Type de boite" />
          <p>{carData.gearbox}</p>
        </div>
        <div className="details-group">
          <img src={transmission} alt="Type de transmission" />
          <p>{carData.transmission}</p>
        </div>
        <div className="details-group">
          <img src={numberOfDoors} alt="Nombre de porte" />
          <p>{carData.doorNumber}</p>
        </div>
        <div className="details-group">
          <img src={numberOfSeat} alt="Nombre de place" />
          <p>{carData.seatNumber}</p>
        </div>

        {!is1600px && (
          <div className="details-group">
            <img src={horsePower} alt="Kilométrage" />
            <p>{carData.mileage} km</p>
          </div>
        )}
      </div>
    );
  };

  const PaintAndInterior = () => {
    return (
      <div className="details-paint-interior">
        <div className="details-group">
          <img src={paint} alt="Peinture" />
          <p>{carData.paint}</p>
        </div>

        <div className="details-group">
          <img src={saddlery} alt="Peinture" />
          <p>{carData.interior}</p>
        </div>
      </div>
    );
  };

  const PaintAndInterior1600px = () => {
    return (
      <div className="details-paint-interior">
        <div className="details-group">
          <img src={productionDate} alt="Date de production du véhicule" />
          <p>{formatDate(carData.dateProd)}</p>
        </div>
        <div className="details-group">
          <img src={horsePower} alt="Kilométrage" />
          <p>{carData.mileage} km</p>
        </div>
        <div className="details-group">
          <img src={paint} alt="Peinture" />
          <p>{carData.paint}</p>
        </div>

        <div className="details-group">
          <img src={saddlery} alt="Peinture" />
          <p>{carData.interior}</p>
        </div>
      </div>
    );
  };

  const CritairAndEmission = () => {
    return (
      <div className="details-critair-emission">
        <div className="details-group">
          {!is1600px && <p>CRIT'AIR</p>}
          <DisplayCritairImg />
        </div>
        <div className="details-group">
          <p>EMISSION CO2</p>
          <DisplayEmissionCo2 />
        </div>
      </div>
    );
  };

  const VehiculeDetailsMobileDisplay = () => {
    return (
      <div className="details-card">
        <div className="details-top-part">
          <FirstDataBlock />
          <SecondDataBlock />
        </div>

        <PaintAndInterior />
        <CritairAndEmission />
      </div>
    );
  };

  const VehiculeDetails970px = () => {
    return (
      <div className="details-card">
        <div className="details-top-part">
          <FirstDataBlock />
          <SecondDataBlock />
          <CritairAndEmission />
        </div>

        <PaintAndInterior />
      </div>
    );
  };

  const VehiculeDetails1600px = () => {
    return (
      <div className="details-card">
        <div className="details-top-part">
          <FirstDataBlock />
          <SecondDataBlock />
          <PaintAndInterior1600px />
          <CritairAndEmission />
        </div>
      </div>
    );
  };

  return (
    <div className="VehiculeDetails">
      {!is970px && <VehiculeDetailsMobileDisplay />}
      {is970px && !is1600px && <VehiculeDetails970px />}
      {is1600px && <VehiculeDetails1600px />}
    </div>
  );
}
