import api from "../../api.js";
import React, { useEffect, useState } from "react";
import "./FavorisElement.scss";
import { useAtom } from "jotai";

import { filteredAuctionListAtom, auctionListAtom } from "../../App.js";

export default function FavorisElement({ auctionId, followStatus }) {
  const [filteredAuctionList, setFilteredAuctionList] = useAtom(
    filteredAuctionListAtom
  );
  const [isFavoris, setIsFavoris] = useState(followStatus);

  const unfollow = async () => {
    const response = await api
      .put("/auction/unfollow/?auctionId=" + auctionId)
      .then((data) => {
        const index = filteredAuctionList.findIndex(
          (item) => item.auction.auctionId === auctionId
        );
        filteredAuctionList[index].followStatus = false;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const follow = async () => {
    const response = await api
      .put("/auction/follow/?auctionId=" + auctionId)
      .then((data) => {
        const index = filteredAuctionList.findIndex(
          (item) => item.auction.auctionId === auctionId
        );
        filteredAuctionList[index].followStatus = true;
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateFollow = async () => {
    if (!isFavoris) {
      follow();
    } else {
      unfollow();
    }
    setIsFavoris(!isFavoris);
  };

  return (
    <>
      {isFavoris ? (
        <svg
          onClick={() => updateFollow()}
          className="FavorisElement"
          width="33"
          height="33"
          fill="#282828"
          viewBox="0 0 33 33"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16.5" cy="16.5" r="16.5" fill="white" />
          <path
            d="M15.9927 24.8619L16.4996 25.1602L17.0066 24.862C21.2609 22.3597 23.7908 19.8534 25.0386 17.5415C26.2995 15.2054 26.2592 13.0268 25.2735 11.4061C23.5096 8.50597 19.2145 8.03642 16.499 11.0469C13.7844 8.03632 9.48978 8.50607 7.72613 11.4062C6.74059 13.0268 6.70029 15.2054 7.96107 17.5415C9.20883 19.8534 11.7386 22.3597 15.9927 24.8619Z"
            stroke="#282828"
            strokeWidth="2"
          />
        </svg>
      ) : (
        <svg
          onClick={() => updateFollow()}
          className="FavorisElement"
          width="33"
          height="33"
          fill="none"
          viewBox="0 0 33 33"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16.5" cy="16.5" r="16.5" fill="white" />
          <path
            d="M15.9927 24.8619L16.4996 25.1602L17.0066 24.862C21.2609 22.3597 23.7908 19.8534 25.0386 17.5415C26.2995 15.2054 26.2592 13.0268 25.2735 11.4061C23.5096 8.50597 19.2145 8.03642 16.499 11.0469C13.7844 8.03632 9.48978 8.50607 7.72613 11.4062C6.74059 13.0268 6.70029 15.2054 7.96107 17.5415C9.20883 19.8534 11.7386 22.3597 15.9927 24.8619Z"
            stroke="#282828"
            strokeWidth="2"
          />
        </svg>
      )}
    </>
  );
}
