import React, { useEffect } from "react";
import "./Informations.scss";
import { atom, useAtom } from "jotai";

import questionMark from "../../../assets/questionMark.svg";
import star from "../../../assets/star.svg";
import frevoIcon from "../../../assets/frevo.svg";

const questionMarkAtom = atom(false);

export default function Informations({ nbStars, optionsPrice, frevo }) {
  const [questionMarkIsHover, setQuestionMarkIsHover] =
    useAtom(questionMarkAtom);

  const DisplayStars = () => {
    const stars = [];
    for (let i = 0; i < nbStars; i++) {
      stars.push(<img src={star} alt="étoile"></img>);
    }
    return <div className="stars">{stars}</div>;
  };

  return (
    <div className="Informations">
      <div className="information-card">
        <div className="mecavin-section">
          <div className="mecavin-section-group">
            <div className="note-mecavin">
              <p>Note Mecavin</p>
              <img
                src={questionMark}
                alt="plus d'information"
                onMouseOver={() => {
                  setQuestionMarkIsHover(true);
                }}
                onMouseOut={() => {
                  setQuestionMarkIsHover(false);
                }}
              />
            </div>
            <DisplayStars />
          </div>
          <div className="mecavin-section-group">
            <p>Montant options</p>
            <p className="options-price">{parseInt(optionsPrice)} €</p>
          </div>
          <p
            className={
              questionMarkIsHover
                ? "info-note-mecavin"
                : "info-note-mecavin-not-displayed"
            }
          >
            Cette note représente le niveau d'équipement du véhicule.
          </p>
        </div>
        <div className="frevo">
          <div className="frevo-text">
            <img src={frevoIcon} alt="" />
            <p>FREVO</p>
          </div>
          <p className="frevo-price">{parseInt(frevo)} €</p>
        </div>
      </div>
    </div>
  );
}
