import api from "../../api.js";
import React, { useEffect } from "react";
import {
  Header,
  Footer,
  HomeCarCard,
  Loader,
  HomeMobileFilter,
  HomeFilterTags,
  SideBar,
} from "../index.js";

import noResult from "../../assets/noResult.svg";
import "./Home.scss";

import Landbot from "../Landbot/Landbot.js";

import { useAtom, atom, useSetAtom } from "jotai";
import {
  isLoadedAtom,
  filteredAuctionListAtom,
  auctionListAtom,
} from "../../App.js";
export const brandListAtom = atom([]);
export const selectedBrandListAtom = atom([]);
export const modelListAtom = atom([]);
export const selectedModelListAtom = atom([]);
export const isFilterOpenAtom = atom(false);

export default function Home() {
  // STATES //
  const [filteredAuctionList, setFilteredAuctionList] = useAtom(
    filteredAuctionListAtom
  );
  const [ourLatestCars, setOurLatestCars] = useAtom(auctionListAtom);
  const [brandList, setBrandList] = useAtom(brandListAtom);
  const setModelList = useSetAtom(modelListAtom);
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);
  const [isFilterOpen, setIsFilterOpen] = useAtom(isFilterOpenAtom);

  function handleBrandList() {
    const brands = ourLatestCars
      .map((auctionElement) => auctionElement.auctionInfo.brand)
      .filter((item, index, self) => self.indexOf(item) === index);
    const formattedBrandList = brands.map((brand) => ({
      value: brand,
      label: brand,
    }));
    setBrandList(formattedBrandList);
    /**
     * [NOTE]
     *
     * Il faut formatter la liste de marque d'une certaine façon
     * car les react-select se base sur des listes formattés
     *  */
  }

  useEffect(() => {
    handleBrandList();
    setFilteredAuctionList(ourLatestCars);
  }, [ourLatestCars]);

  function handleModelList() {
    const unformattedBrandList = brandList.map((item) => item.label);
    const models = ourLatestCars
      .map((auctionElement) => auctionElement.auctionInfo.model)
      .filter((item, index, self) => self.indexOf(item) === index);

    const formattedModelList = models.map((model) => ({
      value: model,
      label: model,
    }));

    setModelList(formattedModelList);
  }

  useEffect(() => {
    handleModelList();
  }, [brandList]);

  /**
   * Cette fonction est appelé par un composant enfant HomeCarCard
   * lorsque le timer de l'offre est à 0.
   *
   * Cette fonction enlève de la liste ourLatestCars l'offre qui vient d'expirer.
   * @param {*} auctionId
   */
  async function handleParentTimeOver(auctionId) {
    const updatedourLatestCars = ourLatestCars.filter((auctionElement) => {
      return auctionElement.auction.auctionId !== auctionId;
    });
    setOurLatestCars(updatedourLatestCars);

    const passedAuction = {
      auctionId: auctionId,
      onSale: "false",
    };

    await api
      .put(
        process.env.REACT_APP_BASE_URI + "/auction/only/auction",
        passedAuction
      )
      .catch((error) => console.log("error"));
  }

  function DisplayCarsCards() {
    if (filteredAuctionList.length === 0 || filteredAuctionList === null) {
      return (
        <div className="home-car-card-wrapper">
          <div className="no-result">
            <img
              className="img-no-result"
              src={noResult}
              alt="pas de résultats pour votre recherche"
            />
            <div className="text-no-result">
              <h2>Pas de résultats trouvés pour votre recherche.</h2>
              <p>Essayez autre chose.</p>
            </div>
          </div>
        </div>
      );
    }

    function compare(auction1, auction2) {
      if (auction1.auction.endAuction < auction2.auction.endAuction) {
        return -1;
      } else if (auction1.auction.endAuction > auction2.auction.endAuction) {
        return 1;
      }
      return 0;
    }

    filteredAuctionList.sort(compare);
    return (
      <div className="home-car-card-wrapper">
        {filteredAuctionList.map((data) => {
          return (
            <HomeCarCard
              carData={data}
              handleParentTimeOver={handleParentTimeOver}
              index={filteredAuctionList.indexOf(data)}
            ></HomeCarCard>
          );
        })}
      </div>
    );
  }

  return (
    <div className="global-home">
      {isLoaded && filteredAuctionList.length >= 0 ? (
        <>
          <SideBar onWhichPage={"home"} />
          <HomeMobileFilter />
          <div className="Home">
            <Header onWhichPage={"home"}></Header>

            <HomeFilterTags />

            <DisplayCarsCards></DisplayCarsCards>

            <div>
              <Landbot />
            </div>

            <Footer></Footer>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
}
