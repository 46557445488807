import React, { useEffect } from "react";
import { atom, useAtom } from "jotai";
import { Timer } from "../../../index.js";
import api from "../../../../api.js";
import "./PictureCTA.scss";
import timeLeft from "../../../../assets/timeLeft.svg";
import timerIcon from "../../../../assets/timerIcon.svg";

import { showDetailAtom } from "../../../How/How.js";

export const modalIsOpenAtom = atom(false);
export const listCompaniesAtom = atom([]);

export default function PictureCTA({ endAuction, idNinox }) {
  const [showDetail, setShowDetail] = useAtom(showDetailAtom);
  const [modalIsOpen, setModalIsOpen] = useAtom(modalIsOpenAtom);
  const [listCompanies, setlistCompanies] = useAtom(listCompaniesAtom);

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const response = await api.get("/user/company").catch((err) => {
      console.log(err);
    });
    const sortCompanies = (listCompanies) => {
      let resultArray = [];

      if (listCompanies) {
        listCompanies.forEach((element) => {
          resultArray.push({
            value: element.siren,
            label: element.name,
            name: element.name,
            phone: element.phone,
            mail: element.mail,
            idNinox: idNinox,
          });
        });
      }
      return resultArray;
    };
    const companies = sortCompanies(response.data);
    setlistCompanies(companies);
  };

  const scrollToHow = () => {
    const howComponent = document.getElementById("how");
    howComponent.scrollIntoView({ behavior: "smooth" });
    if (!showDetail) {
      setShowDetail(true);
    }
  };

  return (
    <div className="Picture-cta">
      <div className="top">
        <button className="blue-fav">
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.9927 24.8619L16.4996 25.1602L17.0066 24.862C21.2609 22.3597 23.7908 19.8534 25.0386 17.5415C26.2995 15.2054 26.2592 13.0268 25.2735 11.4061C23.5096 8.50597 19.2145 8.03642 16.499 11.0469C13.7844 8.03632 9.48978 8.50607 7.72613 11.4062C6.74059 13.0268 6.70029 15.2054 7.96107 17.5415C9.20883 19.8534 11.7386 22.3597 15.9927 24.8619Z"
              stroke="#000167"
              strokeWidth="2"
            />
          </svg>
        </button>
        <Timer date={endAuction}></Timer>
        <button onClick={scrollToHow} className="how-it-works-button">
          Comment ça fonctionne ?
        </button>
      </div>
      <div className="bottom">
        <button
          onClick={() => {
            setModalIsOpen(true);
          }}
          className="orange-button"
        >
          <p>Déposer mon offre</p>
        </button>
      </div>
    </div>
  );
}
