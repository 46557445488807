import React, { useEffect, useState} from "react";
import { useAtom } from "jotai";
import api from "../../api.js";
import "./CopilotLandbot.scss" ;

import { auctionListAtom } from "../../App.js";

export default function CopilotLandbot() {

    const [company, setCompany] = useState(null);
    const [user, setUser] = useState(null);
    const [dataFetched, setDataFetched] = useState(false);

    const [ourLatestCars, setOurLatestCars] = useAtom(auctionListAtom);

    function arrayToString(tableau) {
        try {
            return JSON.stringify(tableau, null, 2);
        } catch (error) {
          console.error("Erreur lors de la conversion du tableau string :", error);
          return "";
        }
    }

    async function getUserInfo(){
        const response = await api
            .get(process.env.REACT_APP_BASE_URI + "/user")
            .catch((e) => {
            console.error(e);
            });  
            setUser(response.data);
    }

    async function getCompanyInfo(){
        const response = await api
            .get(process.env.REACT_APP_BASE_URI + "/user/company")
            .catch((e) => {
            console.error(e);
            });
            setCompany(response.data[0]);
    }

    function displayLandbot() {
        if (dataFetched && user && company) {
            const script = document.createElement("script");
            script.src = "https://cdn.landbot.io/landbot-3/landbot-3.0.0.js";
            script.async = true;

            script.addEventListener("load", () => {
                const myLandbot = new window.Landbot.Container({
                    container: "#landbotCopilot",
                    customData : {
                    siren: company.siren,
                    tel_entreprise: company.phone,
                    email_entreprise: company.mail,
                    first_name: user.firstName,
                    last_name: user.lastName,
                    auctions : arrayToString(ourLatestCars)
                    },
                    configUrl: "https://storage.googleapis.com/landbot.pro/v3/H-1674495-DDU2ZFLW2JF18NEA/index.json",
                });
            });

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            };
        }
    }

    useEffect(() => {
        getCompanyInfo();
        getUserInfo();
        setDataFetched(true);
    }, []);

    useEffect(() => {
        if (dataFetched && user && company && ourLatestCars.length > 0) {
            displayLandbot();
        }
    }, [dataFetched, user, company, ourLatestCars]);

    return (
        <div className="landbotCopilot" id="landbotCopilot"></div>
    )
} 


