import React, { useEffect, useRef } from "react";
import { atom, useAtom } from "jotai";
import { useLocation, useParams } from "react-router";
import api from "../../api.js";


import {
  Header,
  Footer,
  How,
  DetailsCard,
  ExpertCard,
  PictureCard,
  PriceAndCTA,
  ModalDetails,
  Landbot,
} from "../index.js";
import "./Details.scss";
import { modalIsOpenAtom } from "./PictureCard/PictureCTA/PictureCTA.js";

import { isTabletAtom, isDesktopAtom } from "../../App.js";

export const picturesAuctionObjectAtom = atom({});
export const linksListAtom = atom([]);
export const auctionAtom = atom({});

export default function Details() {
  // Ici on récupère l'objet passé lors du click sur le bouton "Voir plus"
  let { state } = useLocation();
  const params = useParams();
  const auctionId = params.auctionId;

  // const auction = getAuction();
  const [auction, setAuction] = useAtom(auctionAtom);
  const [isTablet, setIsTablet] = useAtom(isTabletAtom);
  const [isDesktop, setIsDesktop] = useAtom(isDesktopAtom);
  const [picturesAuctionObject, setPicturesAuctionObject] = useAtom(
    picturesAuctionObjectAtom
  );
  const [linksList, setLinksList] = useAtom(linksListAtom);
  const [modalIsOpen, setModalIsOpen] = useAtom(modalIsOpenAtom);

  useEffect(() => {
    getAuction();
  }, []);

  useEffect(() => {
    getPictures();
    getLinks();
  }, [auction]);

  async function getAuction() {
    if (state) {
      setAuction(state.carData);
    } else {
      const response = await api
        .get(process.env.REACT_APP_BASE_URI + "/auction/" + auctionId)
        .catch((e) => {
          console.error(e);
        });
      setAuction(response.data);
    }
  }
  async function getPictures() {
    if (auctionId) {
      const response = await api
        .get(
          process.env.REACT_APP_BASE_URI + "/auction/cloudinary/" + auctionId
        )
        .catch((e) => {
          console.error(e);
        });
      const tmpArray = picturesAuctionObject;
      const variableTmp = {
        [auctionId]: response.data,
        ...tmpArray,
      };
      setPicturesAuctionObject(variableTmp);
    }
  }

  async function getLinks() {
    if (auctionId) {
      const response = await api
        .get(process.env.REACT_APP_BASE_URI + "/auction/links/" + auctionId)
        .catch((e) => {
          console.error(e);
        });
      setLinksList(response.data);
    }
  }

  function MobileDisplay() {
    if (auction.auctionInfo) {
      return (
        <>
          <div className="pictures-price-cta">
            <PictureCard auctionObject={auction}></PictureCard>
            <PriceAndCTA
              endAuction={auction.auction.endAuction}
              auctionInfo={auction.auctionInfo}
            />
          </div>
          <ExpertCard
            description={auction.auctionInfo.description}
          ></ExpertCard>
          <DetailsCard auctionInfo={auction.auctionInfo}></DetailsCard>
          <How></How>
        </>
      );
    }
  }
  function TabletDisplay() {
    if (auction.auctionInfo) {
      return (
        <div className="details-tablet-display">
          <div className="pictures-price-cta">
            <PictureCard auctionObject={auction}></PictureCard>
            <PriceAndCTA
              endAuction={auction.auction.endAuction}
              auctionInfo={auction.auctionInfo}
            />
          </div>
          <ExpertCard
            description={auction.auctionInfo.description}
          ></ExpertCard>
          <DetailsCard auctionInfo={auction.auctionInfo}></DetailsCard>
          <How></How>
        </div>
      );
    }
  }

  function DesktopDisplay() {
    if (auction.auctionInfo) {
      return (
        <div className="details-desktop-display">
          <PictureCard auctionObject={auction}></PictureCard>
          <div className="desktop-display-wrapper">
            <div className="desktop-display-left">
              <ExpertCard
                description={auction.auctionInfo.description}
              ></ExpertCard>
              <DetailsCard auctionInfo={auction.auctionInfo}></DetailsCard>
            </div>
            <div
              style={{
                width: "360px",
                boxSizing: "border-box",
                top: "8px",
              }}
            >
              <PriceAndCTA
                idNinox={auction.auction.idNinox}
                endAuction={auction.auction.endAuction}
                auctionInfo={auction.auctionInfo}
              ></PriceAndCTA>
            </div>
          </div>
          <How></How>
        </div>
      );
    }
  }

  return (
    <>
      <Header></Header>
      <ModalDetails />
      <div className={modalIsOpen ? "Details details-blurred" : "Details"}>
        {isTablet && !isDesktop ? (
          <TabletDisplay />
        ) : isDesktop ? (
          <DesktopDisplay />
        ) : (
          <MobileDisplay />
        )}
      </div>
      <div>
        <Landbot /> 
      </div>
      
      <Footer></Footer>
    </>
  );
}
