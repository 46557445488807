import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAtom, atom } from "jotai";

import { PictureTitle } from "../../index.js";
import "./PictureCard.scss";

import pictureIcon from "../../../assets/pictureIcon.svg";

import { picturesAuctionObjectAtom } from "../Details.js";
import { isTabletAtom } from "../../../App.js";

const photosURLatom = atom([]);

export default function PictureCard({ auctionObject }) {
  const auctionId = auctionObject.auction.auctionId;
  const [isTablet, setIsTablet] = useAtom(isTabletAtom);
  const [picturesAuctionObject, setPicturesAuctionArray] = useAtom(
    picturesAuctionObjectAtom
  );
  const [photosURL, setPhotosURL] = useAtom(photosURLatom);
  useEffect(() => {
    createPhotosUrl();
  }, [picturesAuctionObject]);

  function createPhotosUrl() {
    const arrayToCross = picturesAuctionObject[auctionId];
    if (arrayToCross) {
      const tmpArray = arrayToCross.map((element) => {
        return element.Url;
      });
      setPhotosURL(tmpArray);
    }
  }

  function PictureCardTabletDisplay() {
    return (

      <div className="photo-grid">
        <div className="nb-pictures">
          <img src={pictureIcon} alt="nombre de photo" />
          <p>{photosURL.length}</p>
        </div>
        <Link
          style={{ width: "100%" }}
          to={"photos/"}
          state={{ photosList: photosURL, selectedPhotoIndex: 0 }}
        >
          <div className="left-photo">
            <img src={photosURL[0]} alt="Photo principale" />
          </div>
        </Link>


        <div className="right-photos">
          <div className="row">
            <Link
              style={{ width: "100%" }}
              to={"photos/"}
              state={{ photosList: photosURL, selectedPhotoIndex: 1 }}
            >
              <img src={photosURL[1]} alt="photo 1" />
            </Link>
            <Link
              style={{ width: "100%" }}
              to={"photos/"}
              state={{ photosList: photosURL, selectedPhotoIndex: 2 }}
            >
              <img src={photosURL[2]} alt="photo 2" />
            </Link>

          </div>
          <div className="row">
            <Link
              style={{ width: "100%" }}
              to={"photos/"}
              state={{ photosList: photosURL, selectedPhotoIndex: 3 }}
            >
              <img src={photosURL[3]} alt="photo 3" />
            </Link>
            <Link
              style={{ width: "100%" }}
              to={"photos/"}
              state={{ photosList: photosURL, selectedPhotoIndex: 4 }}
            >
              <img src={photosURL[4]} alt="photo 4" />
            </Link>

          </div>
        </div >
      </div >
    );
  }

  function PictureCardMobileDisplay() {
    return (
      <>
        <div className="picture-wrapper">
          <Link to={"photos/"} state={{ photosList: photosURL }}>
            <img
              className="first-picture"
              src={photosURL[0]}
              alt="Image principale"
            ></img>
            <div className="nb-pictures">
              <img src={pictureIcon} alt="nombre de photo" />
              <p>{photosURL.length}</p>
            </div>
          </Link>
        </div>
      </>
    );
  }

  return (
    <div className="Picture-card">
      <PictureTitle title={auctionObject.auctionInfo.title}></PictureTitle>
      {isTablet ? <PictureCardTabletDisplay /> : <PictureCardMobileDisplay />}
    </div>
  );
}
